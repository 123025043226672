import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, Subscription, BehaviorSubject } from 'rxjs';
import { UserService } from 'app/user.service';
import { FormGroup, FormBuilder, FormArray } from '@angular/forms';

@Injectable({
    providedIn: 'root'
})
export class CatalogueService {

    error = false;
    errorMessage: any = '';
    Token: any;
    userData: any;
    SearchResult: any = [];
    ProductSpecification: any;
    ProductOptions: any;
    products = new BehaviorSubject<any>([]);
    inventory = new BehaviorSubject<any>([]);
    filterJSON = {};
    page: any = 1;
    limit: any = 60;
    totalCount: any = 0;
    catalogueData: any = [];
    productTitle: any = "ALL PRODUCTS";
    Brick: any;



    constructor(public http: HttpClient, public user: UserService, public fb: FormBuilder) {
        this.Token = localStorage.getItem('Token');
        this.userData = JSON.parse(localStorage.getItem('userData'));
    }

    removeImagePath(body): Observable<any> {
        return this.http.patch<any>(this.user.baseUrlv1 + 'catalogue/deleteImage/', body);
    }

    getCatalogueListByBrand(brandId, page, limit): Observable<any> {
        return this.http.get(this.user.baseUrlv1 + 'catalogue/catalogueListByBrand/' + brandId + '/' + this.page + '/' + this.page);
    }

    getFilters(id, body): Observable<any> {
        return this.http.post<any>(this.user.baseUrlv1 + 'catalogue/filtersForBrand/' + id, body);
    }

    getcataloguesearch(brandid, body, linesheetId): Observable<any> {

        if (linesheetId !== "0") {
            var header = {
                headers: new HttpHeaders({
                    'linesheetId': linesheetId
                })
            }
            return this.http.post(this.user.baseUrlv1 + 'catalogue/searchForBrand/' + brandid + '/' + (this.page - 1) + '/' + this.limit, body,header);
        }
        else {
            return this.http.post(this.user.baseUrlv1 + 'catalogue/searchForBrand/' + brandid + '/' + (this.page - 1) + '/' + this.limit, body);
        }

    }

    getlinesheetcataloguesearch(brandid, body): Observable<any> {
        var header = {
            headers: new HttpHeaders({
                brandId: brandid
            })
        }
        return this.http.post(this.user.baseUrlv1 + 'lineSheetCatalogue/searchForLineSheet/' + (this.page - 1) + '/' + this.limit, body, header);
    }

    getProductDetails(brandID, stylecode): Observable<any> {

        return this.http.get(this.user.baseUrlv1 + 'catalogue/catalogueDetailByStyleCode/' + brandID + '/' + stylecode);
    }

    makeOptionPrimary(data): Observable<any> {
        // console.log(data);
        const body = new FormData();
        body.append('brandId', data.brand_id);
        body.append('styleCode', data.style_code);
        body.append('vendorColourCode', data.vendor_colour_code);
        return this.http.patch(this.user.baseUrlv1 + 'catalogue/assignPrimaryOption/', body);
    }

    uploadImage(file, index, data): Observable<any> {
        var i = index + 1;
        const body = new FormData();
        body.append('brandId', data.brand_id);
        body.append('brandCode', data.vendor_code);
        body.append('styleCode', data.style_code);
        body.append('vendorColorCode', data.vendor_colour_code);
        body.append('index', i);
        body.append('image', file);
        return this.http.patch(this.user.baseUrlv1 + 'catalogue/uploadImageToCatalogue/', body);
    }

    makeOptionPublishB2c(data): Observable<any> {

        var isPublish = false;

        if (data.is_published_b2c) {
            isPublish = false;
        }
        else {
            isPublish = true;
        }

        const body = new FormData();
        body.append('brandId', data.brand_id);
        body.append('styleCode', data.style_code);
        body.append('vendorColourCode', data.vendor_colour_code);
        body.append('is_published_b2c', isPublish.toString());

        return this.http.patch(this.user.baseUrlv1 + 'catalogue/updatePublish/', body);
    }

    makeOptionPublishRetailer(data): Observable<any> {

        var isPublish = false;

        if (data.is_published_retailer) {
            isPublish = false;
        }
        else {
            isPublish = true;
        }

        const body = new FormData();
        body.append('brandId', data.brand_id);
        body.append('styleCode', data.style_code);
        body.append('vendorColourCode', data.vendor_colour_code);
        body.append('is_published_retailer', isPublish.toString());

        return this.http.patch(this.user.baseUrlv1 + 'catalogue/updatePublish/', body);
    }

    makeOptionPublishDistributor(data): Observable<any> {

        var isPublish = false;

        if (data.is_published_distributor) {
            isPublish = false;
        }
        else {
            isPublish = true;
        }

        const body = new FormData();
        body.append('brandId', data.brand_id);
        body.append('styleCode', data.style_code);
        body.append('vendorColourCode', data.vendor_colour_code);
        body.append('is_published_distributor', isPublish.toString());

        return this.http.patch(this.user.baseUrlv1 + 'catalogue/updatePublish/', body);
    }

    getInventory(brandId, stylecode): Observable<any> {
        const body = new FormData();
        body.append('brandId', brandId);
        body.append('style_code', stylecode);
        return this.http.post(this.user.baseUrlv1 + 'brandInventory/getInventoryByStylecode/', body);
    }

    editPublishEAN(data, type, value): Observable<any> {
        const body = new FormData();
        body.append('brandId', data.brand_id);
        body.append('EAN_code', data.EAN_code);
        body.append('type', type);
        body.append('value_to_update', value);
        return this.http.patch(this.user.baseUrlv1 + 'catalogue/editByEAN/', body);
    }

    editPrimaryByEAN(data, value): Observable<any> {
        const body = new FormData();
        body.append('brandId', data.brand_id);
        body.append('EAN_code', data.EAN_code);
        return this.http.patch(this.user.baseUrlv1 + 'catalogue/editPrimaryOptionByEAN/', body);
    }

    getLinesheet(data): Observable<any> {
        const body = new FormData();
        body.append('brandId', data);
        return this.http.post(this.user.baseUrlv1 + 'lineSheet/lineSheetsByBrandForFilter/', body);
    }

    getScanResults(data):Observable<any>{
      const body = new FormData();
      body.append('scannedValue', data);
      return this.http.post(this.user.baseUrlv1 + 'catalogue/catalogueDetailsByScan/', body);
    }

    updateInventory(body):Observable<any>{
      return this.http.post(this.user.baseUrlv1 + 'brandInventory/updateInventory/', body);
    }

    addToBag(data,grade):Observable<any>{
      console.log(data);
      var cartData =       {
        "brand_id":data._id.brand_id,
        "combo_code":data._id.combo_code,
        "bag_id":this.user.selectedBag._id,
        "grade":grade,
        "user_id":this.user.userData._id,
        "user_first_name":this.user.userData.userFirstName,
        "user_last_name":this.user.userData.userLastName
      }
      const body = new FormData();
      body.append('cartData',JSON.stringify([cartData]));
      return this.http.post(this.user.baseUrlv1 + 'brandBagCart/addToCartFromCatalogueListing/', body);
    }

    addALLToBag(body):Observable<any>{
        return this.http.post(this.user.baseUrlv1 + 'brandBagCart/addToCartByCatalogueFilters/', body);
    }

    deleteImage(body):Observable<any>{
      return this.http.patch(this.user.baseUrlv1 + 'catalogue/deleteImage',body)
    }

    uploadFile(body):Observable<any>{
      return this.http.post(this.user.baseUrlv1 + 'catalogue/uploadImagesToS3',body)
    }

    getOrderInsights(body,page,limit):Observable<any>{
      return this.http.post(this.user.baseUrlv1 + 'report/orderAndDispatchCountOfRetailerOrderByStyleCode/'+ page + '/' + limit,body)
    }

    getOrderInsightsByTotal(body):Observable<any>{
      return this.http.post(this.user.baseUrlv1 + 'report/retailerOrderInsightTotalByStyleCode/',body)
    }

    getDistributorOrderInsights(body,page,limit):Observable<any>{
      return this.http.post(this.user.baseUrlv1 + 'report/orderAndDispatchCountOfDistributorOrderByStyleCode/'+ page + '/' + limit,body)
    }

    getDistributorOrderInsightsByTotal(body):Observable<any>{
      return this.http.post(this.user.baseUrlv1 + 'report/distributorOrderInsightTotalByStyleCode/',body)
    }

    getSalesOrderReport(body):Observable<any>{
      return this.http.post(this.user.baseUrlv1 + 'report/brandSaleStatusReport',body)
    }

}
