import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { UserService } from 'app/user.service';
import { Observable } from 'rxjs';
import { IndexedDbService } from 'app/indexed-db.service';

@Injectable({
    providedIn: 'root'
})
export class CartService {

    scanArray:any = [];
    bagName: any;
    constructor(public http: HttpClient, public user: UserService, public db:IndexedDbService) {
      this.db.intialiseDB();
    }

    getRetailers(id): Observable<any> {
        return this.http.get(this.user.baseUrl + 'connection/retailersOfBrandById/' + id)
    }

    getDeliveryLocationDetails(id): Observable<any> {
        return this.http.get(this.user.baseUrl + 'store/storeDetailsById/' + id)
    }


    getRetailerDetails(id): Observable<any> {
        return this.http.get(this.user.baseUrl + 'retailerProfile/retailerDetailsById/' + id)
    }

    getCart(userId, retailerId, deliveryId, page, limit, brandId): Observable<any> {
        const body = new FormData();
        body.append('brand_id', brandId);
        body.append('retailer_id', retailerId);
        body.append('user_id', userId);
        body.append('delivery_id', deliveryId);
        return this.http.post(this.user.baseUrlv1 + 'retailerCart/listingOfCartByEANForRetailer/' + page + '/' + limit, body);
    }

    addtocart(data): Observable<any> {
        const body = new FormData();
        body.append('cartData', JSON.stringify(data));
        return this.http.post(this.user.baseUrlv1 + 'retailerCart/updateCart', body);
    }
    deleteOptionfromCart(body): Observable<any> {
        return this.http.post(this.user.baseUrl + 'retailerCart/deleteItemByColorCodeByRetailer', body);
    }


    getBagData(userId, id, page, limit, brandId): Observable<any> {
        const body = new FormData();
        var header = {
            headers: new HttpHeaders({
                'brandId': brandId,
                'bagId': id,
                'userId': userId,
            })
        }
        return this.http.post(this.user.baseUrlv1 + 'brandBagCart/listingOfCartByEAN/' + page + '/' + limit, body,header);
    }

    getBagDataByComboCode(userId, id, page, limit, brandId,filterbody,is_cutsize,is_forsale): Observable<any> {
        const body = new FormData();
        for (let key of Object.keys(filterbody)) {
            body.append(key, JSON.stringify(filterbody[key]));
        }
        body.append('is_cutsize', JSON.stringify(is_cutsize));
        body.append('is_forsale', JSON.stringify(is_forsale));
        var header = {
            headers: new HttpHeaders({
                'brandId': brandId,
                'bagId': id,
                'userId': userId,
            })
        }
        return this.http.post(this.user.baseUrlv1 + 'brandBagCart/listingOfCartByComboCode/' + page + '/' + limit, body,header);
    }

    updateGrade(body): Observable<any> {
        return this.http.post(this.user.baseUrlv1 + 'brandBagCart/updateGrade/', body);
    }
    updateSets(body): Observable<any> {
        return this.http.post(this.user.baseUrlv1 + 'brandBagCart/updateSetCount/', body);
    }


    addToCartByScan(selectedGrade,bagID,EAN_code){
        var EAN = EAN_code.toString().toUpperCase();

        const body = new FormData();
        body.append('EAN_code',EAN);
        body.append('bag_id',bagID);
        body.append('user_id', this.user.userData._id);
        body.append('user_first_name', this.user.userData.userFirstName);
        body.append('user_last_name', this.user.userData.userLastName);
        body.append('grade',selectedGrade);
          return this.http.post(this.user.baseUrlv1 + 'brandBagCart/addToCartByScan/', body);
    }

    addToBrandBagCart(data){
        const body = new FormData();
        body.append('cartData',JSON.stringify(data));

        return this.http.post(this.user.baseUrlv1 + 'brandBagCart/addToCart/', body);
    }

    deleteOptionfromBag(body):Observable<any>{
      return this.http.post(this.user.baseUrl + 'brandBagCart/deleteItemByColorCode', body);
    }

    getOrdersbyDistributor(body): Observable<any> {
        return this.http.post(this.user.baseUrl + 'distributorOrder/allOrders/0/10000', body);
    }

    getOrdersbyRetailer(body): Observable<any> {
        return this.http.post(this.user.baseUrl + 'retailerOrder/allOrders/0/10000', body);
    }

    getInventoryBucketsByOwner(ownerId):Observable<any>{
      const body = new FormData();
      body.append('owner_id',ownerId);
      return this.http.post(this.user.baseUrl + 'inventoryBucket/getBucketsByOwnerId', body);
    }


    createRetailershipmentDispatch(orderId,shipArray,shipDetails,invoiceFile,warehouseID,bag_id,brand_id):Observable<any>{
      const body = new FormData();
      body.append('orderId',orderId);
      body.append('bag_id',bag_id);
      body.append('brand_id',brand_id);
      body.append('user_id',this.user.userData._id);
      body.append('shipping_data',JSON.stringify(shipArray));
      body.append('transport_name',shipDetails.transportName);
      body.append('tracking_id',shipDetails.trackingId);
      body.append('invoice_id',shipDetails.invoiceId);
      body.append('invoice_file',invoiceFile);
      body.append('invoice_date',shipDetails.invoiceDate);
      body.append('shipment_dispatch_date',shipDetails.shipmentDispatchDate);
      body.append('expected_delivery_date',shipDetails.expectedDeliveryDate);
      body.append('inventory_bucket',JSON.stringify(['ON-HAND','BOX-NOT-OPEN','WIP-30','WIP-60','WIP-90']));
      body.append('warehouse_id',warehouseID);

      return this.http.post(this.user.baseUrl + 'retailerShipment/create', body);
    }

    createDistributorshipmentDispatch(orderId,shipArray,shipDetails,invoiceFile,warehouseID,bag_id,brand_id):Observable<any>{
      const body = new FormData();
      body.append('orderId',orderId);
      body.append('shipping_data',JSON.stringify(shipArray));
      body.append('bag_id',bag_id);
      body.append('brand_id',brand_id);
      body.append('user_id',this.user.userData._id);
      body.append('transport_name',shipDetails.transportName);
      body.append('tracking_id',shipDetails.trackingId);
      body.append('invoice_id',shipDetails.invoiceId);
      body.append('invoice_file',invoiceFile);
      body.append('invoice_date',shipDetails.invoiceDate);
      body.append('shipment_dispatch_date',shipDetails.shipmentDispatchDate);
      body.append('expected_delivery_date',shipDetails.expectedDeliveryDate);
      body.append('inventory_bucket',JSON.stringify(['ON-HAND','BOX-NOT-OPEN','WIP-30','WIP-60','WIP-90']));
      body.append('warehouse_id',warehouseID);
      return this.http.post(this.user.baseUrl + 'distributorShipment/create', body);
    }

    createBagForOrder(poNumber,bag_id,brand_id,combo_codes):Observable<any>{
      const body = new FormData();
      body.append('company_id',this.user.companyId);
      body.append('name',poNumber);
      body.append('bag_id',bag_id);
      body.append('brand_id',brand_id);
      body.append('combo_codes',JSON.stringify(combo_codes));
      body.append('user_id',this.user.userData._id);
      body.append('user_first_name',this.user.userData.userFirstName);
      body.append('user_last_name',this.user.userData.userLastName);

      return this.http.post(this.user.baseUrl + 'brandBag/createBagForOrder', body);
    }

    placeRetailerOrder(body): Observable<any> {
        return this.http.post(this.user.baseUrlv1 + 'retailerOrder/placeOrderByBrand', body);
    }

    getNonAvailableOrder(body): Observable<any> {
        return this.http.post(this.user.baseUrlv1 + 'brandBagCart/getNonAvailableCartItems', body);
    }

    ReviewOrder(body): Observable<any> {
        return this.http.patch(this.user.baseUrlv1 + 'brandBagCart/reviewCartItems', body);
    }

    createExcessBag(body):Observable<any>{
      return this.http.post(this.user.baseUrlv1 + 'brandBagCart/addToExcessBagCart', body)
    }

    placeDistributorOrder(body): Observable<any> {
        return this.http.post(this.user.baseUrlv1 + 'distributorOrder/placeOrderByBrand', body);
    }

    exportBag(body):Observable<any>{
      return this.http.post(this.user.baseUrlv1 + 'brandBagCart/excelDownload', body);
    }

    importBag(body):Observable<any>{
        return this.http.post(this.user.baseUrlv1 + 'brandBagCart/addToCartByImport', body);
    }

    resetBag(body):Observable<any>{
        return this.http.post(this.user.baseUrlv1 + 'brandBagCart/deleteAllItems', body);
    }

    getBagDetails(id):Observable<any>{
      const body = new FormData();
      body.append('bag_id',id);
      return this.http.post(this.user.baseUrlv1 + 'brandBag/getBagInfoByID',body);
    }

    GenerateLink(body):Observable<any>{
        return this.http.post(this.user.baseUrlv1 + 'brandLink/createLink', body);
    }

    getCartSummary(brandId,id,userId):Observable<any>{
        const body = new FormData;
        body.append('brand_id',brandId);
        body.append('bag_id',id);
        body.append('user_id',userId);
        return this.http.post(this.user.baseUrlv1 + 'brandBagCart/totalCost', body);
    }

    getFilters(brandId,id,userId):Observable<any>{
      const body = new FormData;
      var header = {
          headers: new HttpHeaders({
              'brandId': brandId,
              'bagId': id,
              'userId': userId,
          })
      }
        return this.http.post(this.user.baseUrlv1 + 'brandBagCart/filters', body,header);
    }

    updateRatioInCart(body):Observable<any>{
      return this.http.patch(this.user.baseUrlv1 + 'brandBagCart/updateRatioInCart', body);
    }


}
