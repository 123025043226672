import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { UserService } from 'app/user.service';
import { concat, of, from } from 'rxjs';
import { concatMap } from 'rxjs/operators';
import { SuccessComponent } from '../success/success.component';
import { ErrorComponent } from '../error/error.component';
import { CatalogueService } from 'app/catalogue/catalogue.service';

@Component({
  selector: 'app-stock-inwarding',
  templateUrl: './stock-inwarding.component.html',
  styleUrls: ['./stock-inwarding.component.scss']
})
export class StockInwardingComponent implements OnInit {

      keys: any = [];
      keyForQty: any;
      keyForEAN: any;
      errorContainer = false;
      importResult: any = [];
      DoneBtn = false;
      SelectedBuckets:any;
      brands:any = [];
      selectedBrand:any;
      selectedBrandWarehouse:any;
      warehouses:any = [];

      constructor(public dialogRef: MatDialogRef<StockInwardingComponent>,
          @Inject(MAT_DIALOG_DATA) public data, public user: UserService, public api: CatalogueService,public dialog: MatDialog) { }

      ngOnInit(): void {
          this.processArray();
      }

      processArray() {
          this.keys = Object.keys(this.data.array[0]);
          this.user.getBrandsByUserId().subscribe((response:any)=>{
            this.brands = response.data;
            this.selectedBrand = this.brands[0]._id;
            this.getBrandWareHouse();
          })

      }

      getBrandWareHouse(){
        this.user.getBrandsWareHousesByBrandId(this.selectedBrand).subscribe(response => {
          if(response.status == 200){
            this.warehouses = response.data;
            this.selectedBrandWarehouse =  this.warehouses[0]._id
          }
        });
      }

      onNoClick(): void {
          this.dialogRef.close();
      }

      error1 = [];
      importing = false;
      importBag() {
          this.importing = true;
          var array = [];
          for (let x of this.data.array) {
              array.push({
                  EAN_code: x[this.keyForEAN],
                  quantity: x[this.keyForQty],
              })
          }

          const body = new FormData(); 
          body.append('brandId', this.selectedBrand);
          body.append('warehouseId', this.selectedBrandWarehouse);
          body.append('bucketType', this.SelectedBuckets);
          body.append('inventoryData', JSON.stringify(array));
          this.api.updateInventory(body).subscribe((response: any) => {
              this.DoneBtn = true;
              if(response.status == "200"){
                this.openSuccess('Inventory Updated');
              }
              else
              {
                this.errorContainer = true;
                this.error1 = response.data;
              }
            this.importing = false;
          },
              error => {
                this.errorDialog('Something Went Wrong');
                this.importing = false;
              }
          )
      }
      openSuccess(message) {
        this.dialog.open(SuccessComponent, {
              data: {
                  message: message
              },
              width: '300px'
          });
      }

      errorDialog(message) {
        this.dialog.open(ErrorComponent, {
              data: {
                  message: message
              },
              width: '300px'
          });
      }

}
