import { Component, OnInit, ViewChild } from '@angular/core';
import { UploadService } from 'app/uploads/upload.service';
import { FuseProgressBarService } from '@fuse/components/progress-bar/progress-bar.service';
import { MatDialog } from '@angular/material/dialog';
import { UserService } from 'app/user.service';


@Component({
    selector: 'app-manage-flags',
    templateUrl: './manage-flags.component.html',
    styleUrls: ['./manage-flags.component.scss']
})
export class ManageFlagsComponent implements OnInit {

    fileToUpload: File;
    selectedBrand: any;
    disableBtn: boolean = false;
    operation: any = 'Upload';
    selectedFlag: any = 'is_cutsize';
    arrayBuffer = [];
    @ViewChild('fileInput') fileInput: any;

    constructor(public api: UploadService, public progressBar: FuseProgressBarService, public dialog: MatDialog, public user: UserService) { }

    ngOnInit(): void {
    }

    ResetBool() {
        const body = new FormData;
        body.append('brandId', this.selectedBrand._id);
        body.append('type', this.selectedFlag);
        this.api.resetBool(body).subscribe((response:any)=>{
          this.user.openSuccess('Flag Successfully Reset');
        })
    }
    uploadBoolFile() {

        console.log("File Upload",this.fileToUpload.type);
        if (this.fileToUpload.type) {
            let fileReader = new FileReader();
            fileReader.readAsText(this.fileToUpload);
            fileReader.onload = (e) => {
                this.disableBtn = true;
                let csvData = fileReader.result;
                let csvRecordsArray = (<string>csvData).split(/\r\n|\n/);
                let headersRow = this.getHeaderArray(csvRecordsArray);
                if(headersRow.length !== 1 && headersRow[0] !== 'EAN_Code'){
                  this.user.errorDialog('Invalid Column Header, Header should be "EAN_Code"')
                }
                const body = new FormData;
                body.append('brandId', this.selectedBrand._id);
                body.append('type', this.selectedFlag);
                body.append('value_to_update', 'true');
                body.append('filename', this.fileToUpload);
                this.api.uploadBoolFile(body).subscribe((response:any)=>{
                  this.user.openSuccess('File uploaded Successfully');
                  this.resetFile();
                  setTimeout(()=>{
                    this.disableBtn = false;
                    this.dismiss();
                  },2000)
                },error =>{
                    this.disableBtn = false;
                    this.resetFile();
                })
            }
        }
        else {
            this.user.errorDialog('Invalid File, Please Upload a CSV');
        }
    }

    resetFile(){
      this.fileInput.nativeElement.value = "";
      this.fileToUpload = null;
    }

    getHeaderArray(csvRecordsArr: any) {
        let headers = (<string>csvRecordsArr[0]).split(',');
        let headerArray = [];
        for (let j = 0; j < headers.length; j++) {
            headerArray.push(headers[j]);
        }
        return headerArray;
    }

    dismiss(): void {
        this.dialog.closeAll();
    }

    handleFileInput(files: FileList) {
        this.fileToUpload = files.item(0);
    }

}
