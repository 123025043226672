<div fxLayout="row wrap">
    <div fxLayout="column"  fxFlex="100">
        <div class="title">Run Cutsize Algorithm</div>
    </div>
    <ng-container>
        <div fxLayout="column"  fxFlex="100" class="mt-8">
          <mat-form-field class="w-100-p">
            <mat-label> Select Brand </mat-label>
            <mat-select [(ngModel)] = 'selectedBrand'>
              <mat-option [value]="x" *ngFor="let x of api.brands">{{x.brand_name}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
    </ng-container>
    <div fxLayout="column"   fxFlex="100" fxLayoutAlign="center center" fxFlex="100" class="mt-8">
      <button type="button" mat-raised-button color="primary" [disabled]="disableBtn" (click)="runCutsizeAlgo()">
          <span>Run Cutsize Algorithm</span>
      </button>
    </div>
</div>
