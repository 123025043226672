<div fxLayout="row wrap">
    <div fxLayout="column" fxFlex="100">
        <div class="title">Run Cutsize Algorithm</div>
    </div>
    <ng-container>
        <div fxLayout="column" fxFlex="100" class="mt-8">
            <mat-form-field class="w-100-p">
                <mat-label> Select Brand </mat-label>
                <mat-select [(ngModel)]='selectedBrand' required>
                    <mat-option [value]="x" *ngFor="let x of api.brands">{{x.brand_name}}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div fxLayout="column" fxFlex="100" class="mt-8">
            <mat-form-field class="w-100-p">
                <mat-label> Select Flag </mat-label>
                <mat-select [(ngModel)]='selectedFlag' required>
                    <mat-option value="is_cutsize">Cutsize</mat-option>
                    <mat-option value="is_forsale">Sale</mat-option>
                    <mat-option *ngIf="user.config.b2b_retailer" value="publish_retailer">Publish B2B Retailer</mat-option>
                    <mat-option *ngIf="user.config.b2b_distributor" value="publish_distributor">Publish B2B Distributor</mat-option>
                    <mat-option *ngIf="user.config.b2c" value="publish_b2c">Publish B2C</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div fxLayout="column" fxFlex="100" class="mt-8">
            <mat-button-toggle-group [(ngModel)]="operation">
                <mat-button-toggle value="Upload">Upload File</mat-button-toggle>
                <mat-button-toggle value="Reset">Reset</mat-button-toggle>
            </mat-button-toggle-group>
        </div>
        <div fxLayout="column"   fxFlex="100" fxLayoutAlign="center center" *ngIf="operation == 'Upload'" class="mt-8">
          <button type="button" mat-raised-button (click)="fileInput.click()" color="accent">
              <span>Upload CSV File</span>
              <input #fileInput type="file" (change)="handleFileInput($event.target.files)" style="display:none;" />
          </button>
          <span *ngIf="fileToUpload">{{fileToUpload.name}} <mat-icon class="ml-8" style="color:red;cursor:pointer" (click)="resetFile()">close</mat-icon></span>
        </div>
    </ng-container>

    <div fxLayout="column" fxFlex="100" fxLayoutAlign="center center" fxFlex="100" class="mt-8">
        <button type="button" mat-raised-button color="primary" [disabled]="disableBtn" (click)="ResetBool()" *ngIf="operation == 'Reset'">
            <span>Run Reset Algorithm</span>
        </button>
        <button type="button" mat-raised-button color="primary" [disabled]="disableBtn" (click)="uploadBoolFile()" *ngIf="operation == 'Upload'">
            <span>Upload File</span>
        </button>
    </div>
</div>
