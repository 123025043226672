import { Component, OnInit, Inject } from '@angular/core';
import { UserService } from 'app/user.service';
import { CartService } from 'app/cart/cart.service';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-create-bag-dialog',
  templateUrl: './create-bag-dialog.component.html',
  styleUrls: ['./create-bag-dialog.component.scss'],

})
export class CreateBagDialogComponent implements OnInit {


  bagName:any = "";
  remarks:any;
  bag_id:any;
  constructor(public user:UserService,public dialogRef: MatDialogRef<CreateBagDialogComponent>, public dialog:MatDialog,  @Inject(MAT_DIALOG_DATA) public data) {

  if(data.mode == 'Edit'){
    if(data.bag.name)
    this.bagName = data.bag.name;
    if(data.bag.remarks)
    this.remarks = data.bag.remarks;
    if(data.mode == 'Edit'){
      this.bag_id = data.bag._id;
    }
  }


  }

  ngOnInit(): void {
  }


    dismiss(): void {
        this.dialog.closeAll();
    }

  createBag(){
    if(this.bagName.length > 3){
      const body = new FormData();
      body.append('company_id',this.user.companyId);
      body.append('name',this.bagName);
      body.append('remarks',this.remarks);
      body.append('user_id',this.user.userData._id);
      body.append('user_first_name',this.user.userData.userFirstName);
      body.append('user_last_name',this.user.userData.userLastName);

      this.user.createBag(body).subscribe((response:any)=>{
        if(response.status == 200){
             this.user.getBags();
             this.dialogRef.close();
        }
        else{
          this.user.openSnack('Something went wrong,Try Again!!');
        }

      })
    }
    else{
        this.user.openSnack('Bag Name should have more that 3 letters');
    }
  }

  EditBag(){
    if(this.bagName.length > 3){
      const body = new FormData();
      body.append('bag_id',this.bag_id);
      body.append('name',this.bagName);
      body.append('remarks',this.remarks);
      this.user.editBag(body).subscribe((response:any)=>{
        if(response.status == 200){
             this.user.getBags();
             this.dialogRef.close();
        }
        else{
          this.user.openSnack('Something went wrong,Try Again!!');
        }

      })
    }
    else{
        this.user.openSnack('Bag Name should have more that 3 letters');
    }
  }

}
