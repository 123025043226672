import { Injectable } from '@angular/core';
import { UserService } from 'app/user.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UploadService {

  error = false;
  errorMessage: any = '';
  brands: any = [];

  constructor(public user: UserService, public http: HttpClient) {
    this.getBrandsByUserId();
  }

  getBrandsByUserId(){
    this.error = false;
    this.user.getBrandsByUserId().subscribe(response => {
        this.handlegetbrands(response);
    },
        error => {
            this.handlegetbrandserror(error);
        })
  }

  handlegetbrands(response){
    if(response.status == 200){
      this.brands = response.data;
    }
  }
  handlegetbrandserror(error) {
      this.error = true;
      this.errorMessage = "Get Brands Failed";
  }

  getFilesByType(companyId,type,brandArray):Observable<any>{

    const body = new FormData();
    body.append('companyId',companyId);
    body.append('fileType',type);
    body.append('brandId',JSON.stringify(brandArray));

    return this.http.post(this.user.baseUrl + 'fileUpload/filesByFileType',body);

  }

  uploadCatalogueFile(file,fileName,companyId,brandId,userId,userName,userEmail,comment):Observable<any>{

    const body = new FormData();
    body.append('upload_file',file);
    body.append('file_name',fileName);
    body.append('file_type','Catalogue');
    body.append('company_id',companyId);
    body.append('brand_id',brandId);
    body.append('uploaded_user_id',userId);
    body.append('uploaded_user_name',userName);
    body.append('uploaded_user_email',userEmail);

    if(comment != null){
      body.append('comment',comment);
    }

    return this.http.post(this.user.baseUrl + 'fileUpload/uploadFile',body);

  }

  uploadInventoryFile(file,fileName,companyId,brandId,warehouseId,userId,userName,userEmail,comment):Observable<any>{

    const body = new FormData();
    body.append('upload_file',file);
    body.append('file_name',fileName);
    body.append('file_type','Inventory');
    body.append('company_id',companyId);
    body.append('brand_id',brandId);
    body.append('inventory_brand_warehouse_id',warehouseId);
    body.append('uploaded_user_id',userId);
    body.append('uploaded_user_name',userName);
    body.append('uploaded_user_email',userEmail);

    if(comment != null){
      body.append('comment',comment);
    }



    return this.http.post(this.user.baseUrl + 'fileUpload/uploadFile',body);

  }

  uploadDiscountFile(file,fileName,companyId,brandId,connectedId,discountFor,discountOn,userId,userName,userEmail,comment):Observable<any>{

    const body = new FormData();
    body.append('upload_file',file);
    body.append('file_name',fileName);
    body.append('file_type','Discount');
    body.append('company_id',companyId);
    body.append('brand_id',brandId);
    body.append('uploaded_user_id',userId);
    body.append('uploaded_user_name',userName);
    body.append('uploaded_user_email',userEmail);
    body.append('discount_type',discountOn);

    if(discountFor == 'Distributor'){
      body.append('discount_for_distributor_id',connectedId);
    }
    else if(discountFor == 'Retailer'){
      body.append('discount_for_retailer_id',connectedId);
    }

    if(comment != null){
      body.append('comment',comment);
    }



    return this.http.post(this.user.baseUrl + 'fileUpload/uploadFile',body);

  }

  sendNewComment(fileId,comment,userName):Observable<any>{

    const body = new FormData();
    body.append('comment',comment);
    body.append('user_name',userName);

    return this.http.patch(this.user.baseUrl + 'fileUpload/addComment/' + fileId,body);

  }

  getFileDetailsByFileId(fileId):Observable<any>{
    return this.http.get(this.user.baseUrl + 'fileUpload/detailsById/' + fileId);
  }

  uploadS3Images(body):Observable<any>{
    return this.http.post(this.user.baseUrlv1 + 'catalogue/uploadImagesToS3',body)
  }

  uploadInventoryData(body):Observable<any>{
    return this.http.post(this.user.baseUrlv1 + 'brandInventory/uploadInventoryByData',body)
  }

  uploadInventorybyStyle(body):Observable<any>{
    return this.http.post(this.user.baseUrlv1 + 'brandInventory/uploadInventoryBySizeCode',body)
  }

  clearInventoryByBucket(body):Observable<any>{
    return this.http.patch(this.user.baseUrlv1 + 'brandInventory/clearInventoryByBucket',body)
  }

  changeFileStatus(fileId, status): Observable<any> {
    const body = new FormData();
    body.append('status', status);
    return this.http.patch(this.user.baseUrlv1 + 'fileUpload/updateStatus/' + fileId, body);
  }

  deleteFile(id): Observable<any>{
      return this.http.delete(this.user.baseUrlv1 + 'fileUpload/deleteFile/' + id);
  }
  runcutsize(body): Observable<any>{
      return this.http.post(this.user.baseUrlv1 + 'catalogue/updateCutsize/',body);
  }

  uploadBoolFile(body): Observable<any>{
      return this.http.patch(this.user.baseUrlv1 + 'catalogue/updateManagingBoolsByEAN/',body);
  }
  resetBool(body): Observable<any>{
      return this.http.patch(this.user.baseUrlv1 + 'catalogue/resetBools/',body);
  }

}
