import { Component, OnInit } from '@angular/core';
import { UploadService } from 'app/uploads/upload.service';
import { FuseProgressBarService } from '@fuse/components/progress-bar/progress-bar.service';
import { MatDialog } from '@angular/material/dialog';
import { UserService } from 'app/user.service';

@Component({
  selector: 'app-reset-cutsize',
  templateUrl: './reset-cutsize.component.html',
  styleUrls: ['./reset-cutsize.component.scss']
})
export class ResetCutsizeComponent implements OnInit {

  selectedBrand:any;
  disableBtn:boolean = false;
  constructor(public api: UploadService, public progressBar: FuseProgressBarService,public dialog:MatDialog, public user:UserService) { }

  ngOnInit(): void {
  }

  runCutsizeAlgo(){

    this.progressBar.show();
    this.disableBtn = true;
    const body = new FormData;
    body.append('brand_id',this.selectedBrand._id);
    this.api.runcutsize(body).subscribe((response)=>{
      this.progressBar.hide();
      this.disableBtn = false;
      this.user.openSuccess('Cutsize Algorithm Executed');
      setTimeout(()=>{
        this.dismiss();
      },5000);


    })
  }

  dismiss(): void {
      this.dialog.closeAll();
  }

}
