import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { UserService } from 'app/user.service';
import { CartService } from 'app/cart/cart.service';
import { concat, of, from } from 'rxjs';
import { concatMap, catchError } from 'rxjs/operators';
import { SuccessComponent } from '../success/success.component';
import { ErrorComponent } from '../error/error.component';

@Component({
    selector: 'app-file-upload-data',
    templateUrl: './file-upload-data.component.html',
    styleUrls: ['./file-upload-data.component.scss']
})
export class FileUploadDataComponent implements OnInit {

    keys: any = [];
    keyForQty: any;
    keyForEAN: any;
    errorContainer = false;
    importResult: any = [];
    DoneBtn = false;

    constructor(public dialogRef: MatDialogRef<FileUploadDataComponent>,
        @Inject(MAT_DIALOG_DATA) public data, public user: UserService, public api: CartService, public dialog: MatDialog) { }

    ngOnInit(): void {
        this.processArray();
    }

    processArray() {
        this.keys = Object.keys(this.data.array[0]);

    }
    onNoClick(): void {
        this.dialogRef.close();
    }

    error1 = [];
    importing = false;
    importBag() {
        this.importing = true;
        this.DoneBtn = false;
        var array = [];
        for (let x of this.data.array) {
            array.push({
                EAN_code: x[this.keyForEAN],
                quantity: x[this.keyForQty],
            })
        }
        var pages = Math.ceil(array.length / 20);
        var apiArray = this.chunk(array,500);
        var chunkLength = apiArray.length;
        var successDone = [];
        const source = from(apiArray);
        const example = source.pipe(
            concatMap(
                (val: any) => this.uploadCart(val)
            ),
            catchError(err => {
                console.log(err);
                return err
            })
        );
        const subscribe = example.subscribe((response: any) => {
            if(response == 'done'){
              successDone.push(response);
            }
            if(successDone.length == chunkLength){
              this.DoneBtn = true;
            }
        }, error => {
            console.log("error");
        });
    }

    error
    uploadCart(array){
      console.log(array);
      return new Promise((resolve,reject)=>{
        const body = new FormData();
        body.append('bag_id', this.data.bagId);
        body.append('user_id', this.user.userData._id);
        body.append('user_first_name', this.user.userData.userFirstName);
        body.append('user_last_name', this.user.userData.userLastName);
        body.append('grade', 'PF');
        body.append('cartData', JSON.stringify(array));
        this.api.importBag(body).subscribe((response: any) => {
            if (response.data.length == 0) {
                this.user.openSnack("Chunk Updated, Please wait!!");
                resolve('done');
            }
            else {
                this.errorContainer = true;
                this.error1 = this.error1.concat(response.data);
            }
        },
            error => {
                resolve('done');
            }
        )
      })

    }


    openSuccess(message) {
        this.dialog.open(SuccessComponent, {
            data: {
                message: message
            },
            width: '300px'
        });
    }

    errorDialog(message) {
        this.dialog.open(ErrorComponent, {
            data: {
                message: message
            },
            width: '300px'
        });
    }

    chunk(array, size) {
        if (!array.length) {
            return [];
        }
        const head = array.slice(0, size);
        const tail = array.slice(size);

        return [head, ...this.chunk(tail, size)];
    };


}
