<div fxLayout="column" fxFlex fusePerfectScrollbar>

    <mat-list class="date" cdkFocusRegionStart>

        <h3 matSubheader cdkFocusInit>
            <span>Today</span>
        </h3>

        <div class="secondary-text mat-display-1 mb-0 p-16">
            <div class="mb-12">
                {{date | date:'EEEE'}}
            </div>
            <div fxLayout="row" fxLayoutAlign="start start">
                <span> {{date | date:'d'}}</span>
                <span class="mat-subheading-1">th</span>
                <span> {{date | date:'MMMM'}}</span>
            </div>
        </div>
    </mat-list>

    <mat-divider cdkFocusRegionEnd></mat-divider>

    <mat-card *ngFor="let not of user.notifications" class="p-8 border-bottom" [ngClass]="{'new': !not.is_read_by_brand}">
        <p>{{not.notification_msg}}</p>
        <p class="secondary-text">{{not.createdOn | date:'short'}}</p>
    </mat-card>


</div>
