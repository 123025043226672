<div fxLayout="row" fxLayoutAlign="space-between center">
    <div fxLayout="column" fxFlex="90" class="font-size-18 font-weight-700">
        Input Ratio
    </div>
    <div fxLayout="column" fxFlex="10">
        <button mat-icon-button (click)="dismiss()">
            <mat-icon>close</mat-icon>
        </button>
    </div>
</div>
<div fxLayout="row" style="max-height:70vh;overflow-y:auto">
    <div fxLayout="column" fxFlex="100">
        <mat-accordion class="w-100-p p-0">
            <mat-expansion-panel *ngFor="let data of Ratio;let i = index" class="p-0">
                <mat-expansion-panel-header>
                    <mat-panel-title class="font-weight-700">
                        {{data.title}}
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <div fxLayout="row wrap" *ngFor="let grade of data.size_run">
                    <div fxLayout="column" fxFlex="100" class="p-8" style="background:#f5f5f5;">
                        GRADE {{grade.grade}}
                    </div>
                    <div fxLayout="column" fxFlex="25" class="inputWrapper p-4" *ngFor="let size of grade.size">
                        <label class="text-center font-weight-700">{{size.size}}</label>
                        <input type="number" class="number-input" [(ngModel)]="size.value" min="0" step="1" onkeypress="return event.charCode >= 48 && event.charCode <= 57" title="Numbers only">
                    </div>
                </div>

            </mat-expansion-panel>
        </mat-accordion>
    </div>
</div>
<div fxLayout="row" fxLayoutAlign="space-between end">
    <button mat-raised-button class="mr-8" color="primary" (click)="resetRatio1()">Reset Ratio</button>
    <button mat-raised-button color="accent" (click)="setRatioLocal()">Set Ratio </button>
</div>
