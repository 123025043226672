<div fxLayout="row wrap" fxLayoutAlign="space-between center">
    <div fxLayout="column" class="font-weight-700 font-size-18">Generate Link</div>
    <div fxLayout="column">
        <button mat-icon-button (click)="dismiss()">
            <mat-icon>close</mat-icon>
        </button>
    </div>
</div>

<h3 *ngIf="error" style="color:red;font-size:15px;">{{error}}</h3>
<div fxLayout="row wrap" fxLayoutAlign="start center">
    <div fxLayout="column" fxFlex="100">
        <mat-form-field>
            <input matInput placeholder="Link Name" [(ngModel)]="linkName" required>
        </mat-form-field>
    </div>
    <div fxLayout="column" fxFlex="100">
        <mat-form-field>
            <mat-label>Link Type</mat-label>
            <mat-select [(ngModel)]="isPrivate" required>
                <mat-option [value]="true">Private</mat-option>
                <mat-option [value]="false">Public</mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <!-- <div fxLayout="column" fxFlex="90" *ngIf="isPrivate == true">
        <mat-form-field>
            <input matInput placeholder="Mobile Number" [(ngModel)]="mobvalue">
        </mat-form-field>
    </div>
    <div fxLayout="row" fxFlex="10" *ngIf="isPrivate == true">
        <button mat-icon-button (click)="addMobile(mobvalue)">
            <mat-icon>add</mat-icon>
        </button>
    </div>
    <ng-container *ngFor="let mobile of mobileNumbers;let i = index">
        <div fxLayout="column" fxFlex="90" *ngIf="isPrivate == true">
            <mat-form-field>
                <input matInput placeholder="Mobile Number" readonly [(ngModel)]="mobileNumbers[i]">
            </mat-form-field>
        </div>
        <div fxLayout="row" fxFlex="10" *ngIf="isPrivate == true">
            <button mat-icon-button (click)="mobileNumbers.splice(i,1)">
                <mat-icon>delete</mat-icon>
            </button>
        </div>
    </ng-container> -->

    <div fxLayout="column" fxFlex="100">
        <mat-slide-toggle [(ngModel)]="is_published" required>Publish Now</mat-slide-toggle>
    </div>
    <div fxLayout="column" fxFlex="50" *ngIf="!is_published" class="p-4">
        <mat-form-field>
            <mat-label>Publish Date</mat-label>
            <input matInput [matDatepicker]="publishDate" [(ngModel)]="published_date">
            <mat-datepicker-toggle matSuffix [for]="publishDate"></mat-datepicker-toggle>
            <mat-datepicker #publishDate></mat-datepicker>
        </mat-form-field>
    </div>
    <div fxLayout="column" fxFlex="50" class="p-4">
        <mat-form-field>
            <mat-label>Expiry Date</mat-label>
            <input matInput [matDatepicker]="expDate" [(ngModel)]="eDate" required>
            <mat-datepicker-toggle matSuffix [for]="expDate"></mat-datepicker-toggle>
            <mat-datepicker #expDate></mat-datepicker>
        </mat-form-field>
    </div>

</div>
<div fxLayout="row" fxLayoutAlign="end end">
    <button mat-raised-button (click)="generateLink()">Generate Link</button>
</div>
