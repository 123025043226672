<div fxLayout="row" fxLayoutAlign="center center">
    <div fxLayout="column" fxFlex="30">
        <img src="./assets/images/404/error.gif">
    </div>
</div>
<div mat-dialog-content class="text-center">
    <h2>{{data.message}}</h2>
</div>
<div mat-dialog-actions fxLayout="row" fxLayoutAlign="center center">
    <button mat-raised-button (click)="onNoClick()" color="primary">GOT IT</button>
</div>
