import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, Routes } from '@angular/router';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatIconModule } from '@angular/material/icon';
import { InMemoryWebApiModule } from 'angular-in-memory-web-api';
import { TranslateModule } from '@ngx-translate/core';
import 'hammerjs';
import { MatExpansionModule } from '@angular/material/expansion';
import { FuseModule } from '@fuse/fuse.module';
import { FuseSharedModule } from '@fuse/shared.module';
import { FuseProgressBarModule, FuseSidebarModule, FuseThemeOptionsModule } from '@fuse/components';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { fuseConfig } from 'app/fuse-config';
import { FakeDbService } from 'app/fake-db/fake-db.service';
import { AppComponent } from 'app/app.component';
import { AppStoreModule } from 'app/store/store.module';
import { LayoutModule } from 'app/layout/layout.module';
import { LoginpageGuard } from './loginpage.guard';
import { InnerpageGuard } from './innerpage.guard';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { GoogleChartsModule } from 'angular-google-charts';
// Import your AvatarModule
import { AvatarModule } from 'ngx-avatar';
import { SocketioService } from './socketio.service';
import { ConfirmComponent } from './dialogs/confirm/confirm.component';
import { MatDialogModule } from '@angular/material/dialog';
import { SuccessComponent } from './dialogs/success/success.component';
import { ErrorComponent } from './dialogs/error/error.component';
import { FileUploadDataComponent } from './dialogs/file-upload-data/file-upload-data.component';
import { InputRatioComponent } from './dialogs/input-ratio/input-ratio.component';
import { StockInwardingComponent } from './dialogs/stock-inwarding/stock-inwarding.component';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { ProductInventoryComponent } from './dialogs/product-inventory/product-inventory.component';
import { GenerateLinkComponent } from './dialogs/generate-link/generate-link.component';
//import { ShipmentComponent } from './shipment/shipment.component';
import { CreateBagDialogComponent } from './dialogs/create-bag-dialog/create-bag-dialog.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatChipsModule } from '@angular/material/chips';
import { BulkUploadImagesComponent } from './dialogs/bulk-upload-images/bulk-upload-images.component';
import { SocketIoModule, SocketIoConfig } from 'ngx-socket-io';
const config: SocketIoConfig = { url: 'https://ikapi.intrakraft.com', options: {} };
import { NgxIndexedDBModule, DBConfig } from 'ngx-indexed-db';
import { ResetCutsizeComponent } from './dialogs/reset-cutsize/reset-cutsize.component';
import { ManageFlagsComponent } from './dialogs/manage-flags/manage-flags.component';
import { AgmCoreModule } from '@agm/core';
import { WebdatarocksPivotModule } from 'ng-webdatarocks';


const routes: Routes = [
    {
        path: 'apps',
        loadChildren: () => import('./main/apps/apps.module').then(m => m.AppsModule)
    },
    // {
    //     path: 'pages',
    //     loadChildren: () => import('./main/pages/pages.module').then(m => m.PagesModule)
    // },
    {
        path: 'ui',
        loadChildren: () => import('./main/ui/ui.module').then(m => m.UIModule)
    },

    {
        path: 'login',
        loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule),
        canActivate: [LoginpageGuard]
    },
    {
        path: 'dashboard', loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule),
        canActivate: [InnerpageGuard]
    },
    {
        path: 'brands', loadChildren: () => import('./my-brands/my-brands.module').then(m => m.MyBrandsModule),
        canActivate: [InnerpageGuard]
    },
    {
        path: 'stores', loadChildren: () => import('./my-stores/my-stores.module').then(m => m.MyStoresModule),
        canActivate: [InnerpageGuard]
    },
    {
        path: 'distributors', loadChildren: () => import('./my-distributors/my-distributors.module').then(m => m.MyDistributorsModule),
        canActivate: [InnerpageGuard]
    },
    {
        path: 'catalogue',
        loadChildren: () => import('./catalogue/catalogue.module').then(m => m.CatalogueModule),
        canActivate: [InnerpageGuard]
    },
    { path: 'linesheet', loadChildren: () => import('./linesheets/linesheets.module').then(m => m.LinesheetsModule), canActivate: [InnerpageGuard] },
    { path: 'orders', loadChildren: () => import('./orders/orders.module').then(m => m.OrdersModule), canActivate: [InnerpageGuard] },
    { path: 'shipments', loadChildren: () => import('./shipment/shipment.module').then(m => m.ShipmentModule), canActivate: [InnerpageGuard] },
    { path: 'payments', loadChildren: () => import('./payments/payments.module').then(m => m.PaymentsModule), canActivate: [InnerpageGuard] },
    { path: 'reports', loadChildren: () => import('./reports/reports.module').then(m => m.ReportsModule), canActivate: [InnerpageGuard] },
    { path: 'notifications', loadChildren: () => import('./notifications/notifications.module').then(m => m.NotificationsModule), canActivate: [InnerpageGuard] },
    { path: 'cart', loadChildren: () => import('./cart/cart.module').then(m => m.CartModule), canActivate: [InnerpageGuard] },
    { path: 'pages', loadChildren: () => import('./pages/pages.module').then(m => m.PagesModule), canActivate: [InnerpageGuard] },
    { path: 'b2c', loadChildren: () => import('./b2-c/b2-c.module').then(m => m.B2CModule), canActivate: [InnerpageGuard] },
    { path: 'profile', loadChildren: () => import('./profile/profile.module').then(m => m.ProfileModule), canActivate: [InnerpageGuard] },
    { path: 'settings', loadChildren: () => import('./settings/settings.module').then(m => m.SettingsModule), canActivate: [InnerpageGuard] },
    { path: 'upload', loadChildren: () => import('./uploads/uploads.module').then(m => m.UploadsModule) },
    { path: 'links', loadChildren: () => import('./links/links.module').then(m => m.LinksModule) },
    { path: 'grades', loadChildren: () => import('./grades/grades.module').then(m => m.GradesModule) },
    { path: 'groups', loadChildren: () => import('./groups/groups.module').then(m => m.GroupsModule) },
    { path: 'agents', loadChildren: () => import('./agent/agent.module').then(m => m.AgentModule) },
    { path: 'venues', loadChildren: () => import('./venues/venues.module').then(m => m.VenuesModule) },
    {
        path: '**',
        redirectTo: '/login'
    },
];

@NgModule({
    declarations: [
        AppComponent,
        ConfirmComponent,
        SuccessComponent,
        ErrorComponent,
        FileUploadDataComponent,
        InputRatioComponent,
        StockInwardingComponent,
        ProductInventoryComponent,
        GenerateLinkComponent,
        CreateBagDialogComponent,
        BulkUploadImagesComponent,
        ResetCutsizeComponent,
        ManageFlagsComponent
        //  ShipmentComponent
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        RouterModule.forRoot(routes, {
            scrollPositionRestoration: 'top',
            onSameUrlNavigation: 'reload'
        }),
        TranslateModule.forRoot(),
        InMemoryWebApiModule.forRoot(FakeDbService, {
            delay: 0,
            passThruUnknownUrl: true
        }),

        AvatarModule,
        MatButtonToggleModule,
        // Material moment date module
        MatMomentDateModule,

        // Material
        MatButtonModule,
        MatIconModule,
        MatSnackBarModule,
        // Fuse modules
        FuseModule.forRoot(fuseConfig),
        FuseProgressBarModule,
        FuseSharedModule,
        FuseSidebarModule,
        FuseThemeOptionsModule,

        // App modules
        LayoutModule,
        AppStoreModule,
        MatButtonModule,
        MatDialogModule,
        MatFormFieldModule,
        MatInputModule,
        MatSelectModule,
        MatExpansionModule,
        GoogleChartsModule.forRoot({ mapsApiKey: 'AIzaSyAqH2ykirMYfbIvcunkXlo7WaJJCsWmbQE' }),
        AgmCoreModule.forRoot({
            apiKey: 'AIzaSyAqH2ykirMYfbIvcunkXlo7WaJJCsWmbQE',
            libraries: ['places']
        }),
        MatSlideToggleModule,
        MatDatepickerModule,
        MatAutocompleteModule,
        MatChipsModule,
        SocketIoModule.forRoot(config),
        WebdatarocksPivotModule
        // NgxIndexedDBModule.forRoot(dbConfig)
    ],
    bootstrap: [
        AppComponent
    ],
    providers: [SocketioService,
        { provide: MAT_DATE_LOCALE, useValue: 'en-GB' }]
})
export class AppModule {
}
