import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CatalogueService } from 'app/catalogue/catalogue.service';
import { from } from 'rxjs';
import { reduce, groupBy, mergeMap, toArray, findIndex } from 'rxjs/operators';
import { UserService } from 'app/user.service';

@Component({
    selector: 'app-product-inventory',
    templateUrl: './product-inventory.component.html',
    styleUrls: ['./product-inventory.component.scss']
})
export class ProductInventoryComponent implements OnInit {

    myData = [
        ['ON-HAND', 0],
        ['Frozen', 0],
        ['WIP-30', 0],
        ['WIP-60', 0],
    ];
    Chartoptions = {
        width: 400,
        height: 400,
        pieHole: 0.5,
        pieSliceTextStyle: {
            color: 'black',
        },
        legend: { position: 'bottom', maxLines: 10 },
    };
    inventoryData: any = [];
    order: any = ["FS", "3XS", "2XS", 'XS', 'S', 'M', 'L', 'XL', 'XXL', "3XL", "4XL", "5XL", "XSS", "SM", "ML", "LXL", "NB", "00", "02", "04", "06", "08", "10", "12", "14", "16", "18", "20", "22", "24", "25", "26", "27", "28", "29", "30", "31", "32", "33", "34", "35", "36", "37", "38", "39", "40", "42", "44", "46", "48", "50", "52", "54", "56", "58"];
    constructor(public api: CatalogueService, public user: UserService, public dialogRef: MatDialogRef<ProductInventoryComponent>, @Inject(MAT_DIALOG_DATA) public data) {
        this.getDetails()
    }

    ngOnInit(): void {
    }

    getDetails() {
        this.api.getProductDetails(this.data._id.brand_id, this.data.style_code).subscribe(response => {
            this.handlegetProductDetails(response);
        })
    }

    products: any = [];
    handlegetProductDetails(response) {
        if (response.status == 200) {
            var products = response.data;
            this.products = response.data

            var productOptions = [];
            const source = from(products);
            const groupedbyColorcode = source.pipe(
                groupBy((data: any) => data.vendor_colour_code),
                mergeMap(group => group.pipe(toArray()))
            );
            const subscribe = groupedbyColorcode.subscribe(
                val => {
                    var array = [];
                    for (let x of this.order) {
                        for (let data of val) {
                            if (x == data.size_code) {
                                array.push(data);
                            }
                        }
                    }
                    productOptions.push(array)
                });

            for (let x of productOptions) {
                if (x[0].combo_code == this.data._id.combo_code) {
                    this.inventoryData = [x];
                }
            }

            console.log(this.inventoryData);
            this.processData(this.inventoryData);
        }
    }

    inventory = [];
    processData(data) {

        this.inventory = [];
        for (let x of data[0]) {
            this.inventory.push({
                EAN_code: x.EAN_code,
                size: x.size_code,
                stock: this.processStock(x.stock),
                frozen_stock:x.frozen_stock
            })
        }
        this.pieChartData(this.inventory);
    }

    pieChartData(data) {
        var allStock = [];
        for(let x of data){
          for(let y of x.stock){
            allStock.push(y);
          }
        }
        var buckets = [];
        const source = from(allStock);
        const groupedbyColorcode = source.pipe(
            groupBy((data: any) => data.bucket),
            mergeMap(group => group.pipe(toArray()))
        );
        const subscribe = groupedbyColorcode.subscribe(
            val => {
                buckets.push([val[0].bucket,this.availabeSumm(val)]);
            });
       this.myData = buckets;
    }

    processStock(stock) {
        var stockArray = [];
        var buckets = [];
        const source = from(stock);
        const groupedbyColorcode = source.pipe(
            groupBy((data: any) => data.bucket_name),
            mergeMap(group => group.pipe(toArray()))
        );
        const subscribe = groupedbyColorcode.subscribe(
            val => {
                buckets.push(val);
            });

        for (let x of this.user.bucketNames) {
            var array = from(buckets);
            var result = array.pipe(findIndex((data: any) => {
                return data[0].bucket_name === x
            }
            ));

            result.subscribe(index => {
                if (index == -1) {
                    stockArray.push({
                        bucket: x,
                        quantity: 0
                    })
                }
                else {
                    stockArray.push({
                        bucket: x,
                        quantity: this.availabeSumm(buckets[index])
                    })
                }
            })

        }
        return stockArray
    }

    dismiss() {
        this.dialogRef.close({
        });
    }


    getAvailableSum(data) {
        // console.log(data);
        var array = [];
        for (let x of data) {
            if (x.type !== 'Frozen' && x.bucket !== 'BOMBAY_GOODS'  && x.bucket !== 'RETURNS' && x.bucket !== 'ALTERATIONS')
                array.push(x)
        }
        var sum = 0;

        const source = from(array);
        const example = source.pipe(reduce((acc, val) =>
            acc + val.quantity, 0));
        example.subscribe(x => sum = x);
        return sum
    }

    getTotal() {
        var stocks = [];
        for (let x of this.inventory) {
            for (let y of x.stock) {
                if (y.bucket !== 'Frozen' && y.bucket !== 'BOMBAY_GOODS'  && y.bucket !== 'RETURNS' && y.bucket !== 'ALTERATIONS') {
                    stocks.push(y)
                }
            }
        }
        var sum = 0;
        const source = from(stocks);
        const example = source.pipe(reduce((acc, val) =>
            acc + val.quantity, 0));
        example.subscribe(x => sum = x);

        return sum
    }

    getFrozen() {
        var stocks = [];

        for (let x of this.inventory) {

            for (let y of x.frozen_stock) {
                stocks.push(y)
            }
        }
        var sum = 0;
        const source = from(stocks);
        const example = source.pipe(reduce((acc, val) =>
            acc + val.quantity, 0));
        example.subscribe(x => sum = x);
        return sum
    }

    availabeSumm(data){
      console.log("Sum",data);
      var array = [];
      for (let x of data) {
              array.push(x);
      }
      var sum = 0;
      const source = from(array);
      const defectiveSum = source.pipe(reduce((acc, val) =>
          acc + val.quantity, 0));
      defectiveSum.subscribe(x => sum = x);

      console.log("Sum",sum);
      return sum
    }

    availableSum(data) {
        var array = [];
        for (let x of data) {
            if (x.bucket !== 'Frozen' && x.bucket !== 'BOMBAY_GOODS'  && x.bucket !== 'RETURNS' && x.bucket !== 'ALTERATIONS') {
                array.push(x);
            }
        }
        var sum = 0;
        const source = from(array);
        const defectiveSum = source.pipe(reduce((acc, val) =>
            acc + val.quantity, 0));
        defectiveSum.subscribe(x => sum = x);
        return sum
    }
    availableSumMinusFrozen(data) {
        var array = [];
        var frozenArray = [];
        for (let x of data) {
            if (x.bucket !== 'Frozen' && x.bucket !== 'BOMBAY_GOODS'  && x.bucket !== 'RETURNS' && x.bucket !== 'ALTERATIONS') {
                array.push(x);
            }
            else {
                frozenQty = x.quantity
            }
        }
        for (let x of data) {
            if (x.bucket == 'Frozen') {
                frozenArray.push(x);
            }
        }
        var sum = 0;
        const source = from(array);
        const defectiveSum = source.pipe(reduce((acc, val) =>
            acc + val.quantity, 0));
        defectiveSum.subscribe(x => sum = x);

        var frozenQty = 0;
        const fsource = from(frozenArray);
        const fdefectiveSum = fsource.pipe(reduce((acc, val) =>
            acc + val.quantity, 0));
        fdefectiveSum.subscribe(x => frozenQty = x);

        return sum - frozenQty
    }

    getSumofBucket(bucketName){
      var stocks = [];
      for (let x of this.inventory) {
          for (let y of x.stock) {

              if (y.bucket == bucketName) {
                  stocks.push(y)
              }
          }
      }
      // console.log(stocks);
      var sum = 0;
      const source = from(stocks);
      const example = source.pipe(reduce((acc, val) =>
          acc + val.quantity, 0));
      example.subscribe(x => sum = x);
      return sum
    }

}
