import { Injectable } from '@angular/core';
// import { NgxIndexedDBService, ObjectStoreMeta } from 'ngx-indexed-db';

import { openDB, DBSchema } from 'idb';

@Injectable({
    providedIn: 'root'
})
export class IndexedDbService {

    constructor() {
        this.intialiseDB();
    }

    async intialiseDB() {
        openDB('iRomp', 1, {
            upgrade(db) {
                var store = db.createObjectStore('cart',
                    { keyPath: '_id', autoIncrement: true });
                var index = store.createIndex("bagID", ["bag_id", "brand_id"]);
                var index2 = store.createIndex("bag", ["bag_id"]);
            },
        }).then((data) => {
            console.log("Cart DB Created");
        }).catch((err) => {
            // console.log(err);
            alert("Your Browser Does not support the functions required to run this website. Contact support@crosscolor.in or accounts@intrakraft.com");
        });
    }

    async addToCartDB(data) {
        const db1 = await openDB('iRomp', 1);
        var key;
        let transaction: any = db1.transaction('cart', 'readwrite');
        await transaction.objectStore('cart').add(data).then((val) => {
          key = val;
        });
        transaction.done;
        db1.close();
        return key
    }

    async updateCart(id,data) {

      const db1 = await openDB('iRomp', 1);
      let transaction = db1.transaction('cart', 'readwrite');
      const value = await transaction.objectStore('cart').get(id);
      value['bagAdd'] = data;
      transaction.objectStore('cart').put(value);
      transaction.done;
      db1.close();
      return transaction
    }

    getCart(bag_id, brand_id) {
        return new Promise(async (resolve, reject) => {
            const db1 = await openDB('iRomp', 1);
            let transaction = db1.transaction('cart');
            const cartIndex = transaction.objectStore('cart').index('bagID');
            let value = await cartIndex.getAll([bag_id, brand_id]);
            resolve(value);
        });
    }

    async deleteALLCart(bag_id) {
        const db1 = await openDB('iRomp', 1);
        let transaction = db1.transaction('cart', 'readwrite');
        const cartIndex = transaction.objectStore('cart').index('bag');
        let value = await cartIndex.getAll([bag_id]);
        console.log(value);
        if (value.length > 0) {

            for (let data of value) {
                transaction.objectStore('cart').delete(data._id);
            }
        }
        transaction.done;
        db1.close();
        return transaction
    }
    async deleteALLBrandCart(bag_id, brand_id) {
        const db1 = await openDB('iRomp', 1);
        let transaction = db1.transaction('cart', 'readwrite');
        const cartIndex = transaction.objectStore('cart').index('bagID');
        let value = await cartIndex.getAll([bag_id, brand_id]);
        console.log(value);
        if (value.length > 0) {
            for (let data of value) {
                transaction.objectStore('cart').delete(data._id);
            }
        }
        transaction.done;
        db1.close();
        return transaction
    }



}
