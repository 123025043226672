<div fxLayout="row wrap">
    <div fxLayout="column" fxFlex="100" *ngIf="invalidFiles.length == 0">
        <div class="title">Upload Catalogue File</div>
    </div>
    <div fxLayout="column" fxFlex="100" *ngIf="invalidFiles.length > 0">
        <div class="title">Invalid Files</div>
        <div style="font-size:14px; font-weight:700">Files Not Found!! Please upload the catalogue before uploading these files</div>
    </div>
    <ng-container *ngIf="invalidFiles.length == 0">
        <div fxLayout="column" fxFlex="100" class="mt-8">
            <mat-form-field class="w-100-p">
                <mat-label> Select Brand </mat-label>
                <mat-select [(ngModel)]='selectedBrand'>
                    <mat-option [value]="x" *ngFor="let x of api.brands">{{x.brand_name}}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div fxLayout="column" fxFlex="100" class="my-8">
        <label>File name should in the "Stylecode"-"Optioncode"-"#".JPG format, example: 10001-A-1.JPG</label>
        </div>
        <div fxLayout="column" fxFlex="100" fxLayoutAlign="center center">
            <button type="button" mat-raised-button color="primary" (click)="fileInput.click()">
                <span>Select Files</span>
                <input #fileInput type="file" multiple (change)="handleFileInput($event.target.files)" style="display:none;" />
            </button>
        </div>

    </ng-container>
    <div fxLayout="row wrap" fxLayoutAlign="start start" style="height:300px;overflow-y:auto" fxFlex="100" class="mt-8" *ngIf="files.length > 0 && invalidFiles.length == 0">
        <div fxLayout="row wrap"  fxLayoutAlign="space-between start" *ngFor="let f of files" fxFlex="100">
            <div fxLayout="column" [ngClass]="{'error': f.error}">
                {{f.name}}
            </div>
            <div fxLayout="column" *ngIf="f.error" [ngClass]="{'error': f.error}">
              {{f.errormsg}}
            </div>
            <div fxLayout="column">
                <mat-icon style="color:green" *ngIf="!f.error">check</mat-icon>
                <mat-icon style="color:red" *ngIf="f.error">close</mat-icon>
            </div>
        </div>
    </div>
    <div fxLayout="row" fxLayoutAlign="end end" class="mt-16 w-100-p" *ngIf="invalidFiles.length == 0">
      <button mat-raised-button (click)="files = []">Reset</button>
      <button mat-raised-button color="accent" (click)="UploadFiles()">Upload to Server</button>
    </div>

    <div fxLayout="row wrap" fxLayoutAlign="start start" style="height:300px;overflow-y:auto" fxFlex="100" class="mt-8" *ngIf="invalidFiles.length > 0">
        <div fxLayout="row wrap"  fxLayoutAlign="space-between start" *ngFor="let f of invalidFiles" fxFlex="100">
            <div fxLayout="column">
                {{f.fileName}}
            </div>
            <div fxLayout="column">
              Option Not Found
            </div>
            <div fxLayout="column">
                <mat-icon style="color:red">close</mat-icon>
            </div>
        </div>
    </div>
</div>
