import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UserService } from 'app/user.service';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class RatiosService {

    OriginalRatio: any = [
        {
            Brick: "Shirts",
            attribute_data: [{
                key: "Sleeve",
                value: 'Full Sleeve'
            }],
            title: 'Full Sleeve Shirts',
            size_run: [
                {
                    grade: 'A',
                    size: [
                        { size: '38', value: 0 },
                        { size: '39', value: 0 },
                        { size: '40', value: 0 },
                        { size: '42', value: 0 },
                        { size: '44', value: 0 },
                        { size: '46', value: 0 }
                    ]
                },
                {
                    grade: 'B',
                    size: [
                        { size: '38', value: 0 },
                        { size: '39', value: 0 },
                        { size: '40', value: 0 },
                        { size: '42', value: 0 },
                        { size: '44', value: 0 },
                        { size: '46', value: 0 }]
                },
                {
                    grade: 'C',
                    size: [
                        { size: '38', value: 0 },
                        { size: '39', value: 0 },
                        { size: '40', value: 0 },
                        { size: '42', value: 0 },
                        { size: '44', value: 0 },
                        { size: '46', value: 0 }]
                },
                {
                    grade: 'D',
                    size: [
                        { size: '38', value: 0 },
                        { size: '39', value: 0 },
                        { size: '40', value: 0 },
                        { size: '42', value: 0 },
                        { size: '44', value: 0 },
                        { size: '46', value: 0 }]
                }
            ]
        },
        {
            Brick: "Shirts",
            attribute_data: [{
                key: "Sleeve",
                value: 'Half Sleeve'
            }],
            title: 'Half Sleeve Shirts',
            size_run: [
                {
                    grade: 'A',
                    size: [
                        { size: '38', value: 0 },
                        { size: '39', value: 0 },
                        { size: '40', value: 0 },
                        { size: '42', value: 0 },
                        { size: '44', value: 0 },
                        { size: '46', value: 0 }
                    ]
                },
                {
                    grade: 'B',
                    size: [
                        { size: '38', value: 0 },
                        { size: '39', value: 0 },
                        { size: '40', value: 0 },
                        { size: '42', value: 0 },
                        { size: '44', value: 0 },
                        { size: '46', value: 0 }]
                },
                {
                    grade: 'C',
                    size: [
                        { size: '38', value: 0 },
                        { size: '39', value: 0 },
                        { size: '40', value: 0 },
                        { size: '42', value: 0 },
                        { size: '44', value: 0 },
                        { size: '46', value: 0 }]
                },
                {
                    grade: 'D',
                    size: [
                        { size: '38', value: 0 },
                        { size: '39', value: 0 },
                        { size: '40', value: 0 },
                        { size: '42', value: 0 },
                        { size: '44', value: 0 },
                        { size: '46', value: 0 }]
                }
            ]
        },
        {
            Brick: "Jeans",
            attribute_data: [{}],
            title: 'Jeans',
            size_run: [
                {
                    grade: 'A',
                    size: [
                        { size: '28', value: 0 },
                        { size: '30', value: 0 },
                        { size: '32', value: 0 },
                        { size: '34', value: 0 },
                        { size: '36', value: 0 },
                        { size: '38', value: 0 },
                        { size: '40', value: 0 }]
                },
                {
                    grade: 'B',
                    size: [
                        { size: '28', value: 0 },
                        { size: '30', value: 0 },
                        { size: '32', value: 0 },
                        { size: '34', value: 0 },
                        { size: '36', value: 0 },
                        { size: '38', value: 0 },
                        { size: '40', value: 0 }]
                },
                {
                    grade: 'C',
                    size: [
                        { size: '28', value: 0 },
                        { size: '30', value: 0 },
                        { size: '32', value: 0 },
                        { size: '34', value: 0 },
                        { size: '36', value: 0 },
                        { size: '38', value: 0 },
                        { size: '40', value: 0 }]
                },
                {
                    grade: 'D',
                    size: [
                        { size: '28', value: 0 },
                        { size: '30', value: 0 },
                        { size: '32', value: 0 },
                        { size: '34', value: 0 },
                        { size: '36', value: 0 },
                        { size: '38', value: 0 },
                        { size: '40', value: 0 }]
                }
            ]
        },
        {
            Brick: "Trousers",
            attribute_data: [{}],
            title: 'Trousers',
            size_run: [
                {
                    grade: 'A',
                    size: [
                        { size: '28', value: 0 },
                        { size: '30', value: 0 },
                        { size: '32', value: 0 },
                        { size: '34', value: 0 },
                        { size: '36', value: 0 },
                        { size: '38', value: 0 },
                        { size: '40', value: 0 },
                        { size: '42', value: 0 }]
                },
                {
                    grade: 'B',
                    size: [
                        { size: '28', value: 0 },
                        { size: '30', value: 0 },
                        { size: '32', value: 0 },
                        { size: '34', value: 0 },
                        { size: '36', value: 0 },
                        { size: '38', value: 0 },
                        { size: '40', value: 0 },
                        { size: '42', value: 0 }]
                },
                {
                    grade: 'C',
                    size: [
                        { size: '28', value: 0 },
                        { size: '30', value: 0 },
                        { size: '32', value: 0 },
                        { size: '34', value: 0 },
                        { size: '36', value: 0 },
                        { size: '38', value: 0 },
                        { size: '40', value: 0 },
                        { size: '42', value: 0 }]
                },
                {
                    grade: 'D',
                    size: [
                        { size: '28', value: 0 },
                        { size: '30', value: 0 },
                        { size: '32', value: 0 },
                        { size: '34', value: 0 },
                        { size: '36', value: 0 },
                        { size: '38', value: 0 },
                        { size: '40', value: 0 },
                        { size: '42', value: 0 }]
                }
            ]
        }
    ];

    constructor(public http: HttpClient, public user: UserService) { }

    setBagRatio(body): Observable<any> {
        return this.http.post(this.user.baseUrlv1 + 'brandBagCart/setRatio/', body);
    }

    getBagRatio(body): Observable<any> {
        return this.http.post(this.user.baseUrlv1 + 'brandBagCart/getRatio/', body);
    }

}
