<div fxLayout="row wrap" style="height:70vh;overflow-y:auto">
  <div fxLayout="row wrap" fxFlex="100">
    <div class="w-100-p">
        <h3 class="font-weight-700">{{data._id.combo_code}} - {{data.product_name}}</h3>
    </div>
  </div>
    <div fxLayout="row wrap" fxLayoutAlign="center center" fxFlex="50">
        <div class="Datacontainer">
            <div class="w-100-p font-size-20 font-weight-700">{{getTotal()}}</div>
            <div class="w-100-p">Total Inventory</div>
        </div>
        <div class="Datacontainer">
            <div class="w-100-p font-size-20 font-weight-700">{{getFrozen()}}</div>
            <div class="w-100-p">Frozen Inventory</div>
        </div>
        <div class="Datacontainer">
            <div class="w-100-p font-size-20 font-weight-700">{{getTotal() - getFrozen() }}</div>
            <div class="w-100-p">Available Inventory</div>
        </div>
    </div>
    <div fxLayout="row wrap" fxLayoutAlign="center center" fxFlex="50">
        <div fxLayout="column" fxFlex="100" fxLayoutAlign="center center">
            <google-chart [title]="'Status Vs Inventory'" [type]="'PieChart'" [data]="myData" [options]="Chartoptions">
            </google-chart>
        </div>
    </div>
    <div fxLayout="row wrap" fxFlex="100">
      <div fxLayout="column" fxFlex="100" class="p-4">
          <table class="border p-12 specTable w-100-p">
              <tr style="background:#ededff">
                  <th class="font-size-14">EAN Code</th>
                  <th class="font-size-14">Size</th>
                  <th *ngFor="let bucket of user.bucketNames">
                      {{bucket}}</th>
                  <th class="redColor">Frozen</th>
                  <th class="font-size-14" style="color:green">Available</th>
                  <th class="font-size-14" style="background: #eeeeee;">Inventory</th>
              </tr>
              <tr *ngFor="let value of inventory">
                  <th class="font-size-14 border">{{value.EAN_code}}</th>
                  <th class="font-size-14 border-bottom">{{value.size}}</th>
                  <th class="font-size-14 border-bottom" *ngFor="let stock of value.stock">
                      {{stock.quantity}}</th>
                  <th class="redColor">{{availableSum(value.frozen_stock)}}</th>
                  <th class="font-size-14 border-bottom" style="color:green">{{availableSum(value.stock) - availableSum(value.frozen_stock)}}</th>
                  <th class="font-size-14 border-bottom" style="background: #eeeeee;">{{availableSum(value.stock)}}</th>
              </tr>
              <tr style="background: #0d0a12;color: white">
                  <th class="font-size-14 border"></th>
                  <th class="font-size-14 border-bottom"></th>
                  <th class="font-size-14 border-bottom" *ngFor="let  bucket of user.bucketNames">
                      {{getSumofBucket(bucket)}}</th>
                  <th>{{getFrozen()}}</th>
                  <th>{{getTotal() - getFrozen() }}</th>
                  <th>{{getTotal()}}</th>
              </tr>
          </table>
      </div>
    </div>
</div>
