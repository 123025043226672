import { Injectable } from '@angular/core';
import * as io from 'socket.io-client';
import { UserService } from './user.service';
import { Socket } from 'ngx-socket-io';

@Injectable({
    providedIn: 'root'
})


export class SocketioService {

    constructor(public socket:Socket ,public user:UserService) { }
    setupSocketConnection() {
         this.establishconnection();

    }

    establishconnection() {
      this.user.getBrandsByUserId().subscribe((response:any)=>{
        for(let b of response.data){
          this.socket.on(b._id, data => {
            this.user.openSnack('You have a new notification');
             this.user.getNotification();
             this.user.beep();
          });
        }
      });
    }
}
