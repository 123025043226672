import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, BehaviorSubject, from } from 'rxjs';
import { share, filter } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { SuccessComponent } from './dialogs/success/success.component';
import { ErrorComponent } from './dialogs/error/error.component';
import { ConfirmComponent } from './dialogs/confirm/confirm.component';
import { Observer, fromEvent, merge } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class UserService {

    systemStatus: boolean = true;
    Token: any;
    userData: any;
    // Production
    companyId = '5f2b8c736047c32705e97d82';
    bags: any = new BehaviorSubject<any>([]);
    bagNames: any = [];
    selectedBag: any;
    // baseUrl = 'http://localhost:5001/api/v2/';
    // baseUrlv1 = 'http://localhost:5001/api/v2/';
    // baseUrl = 'http://192.168.0.166:5001/api/v1/';
    // baseUrlv1 = 'http://192.168.0.166:5001/api/v1/';
    baseUrl = 'https://ikapi.intrakraft.com/api/v2/';
    baseUrlv1 = 'https://ikapi.intrakraft.com/api/v2/';


    remoteDb = 'https://cjcouch.intrakraft.com/';

    brand_id = "5f2b8da96047c32705e97d83";
    brand_name = "CrossColor";


    // bucketNames = ["ON-HAND", "BOX-NOT-OPEN", "SAMPLES", "WIP-30", "WIP-60", "WIP-90", "WIP-120", "Frozen","BOMBAY_GOODS","ALTERATIONS","RETURNS"];
    bucketNames = ["ON-HAND"];

    config = {
        b2c: false,
        b2b_retailer: true,
        b2b_distributor: false
    }

    constructor(public router: Router, public http: HttpClient, private _snackBar: MatSnackBar, public dialog: MatDialog) {
        this.Token = localStorage.getItem('Token');
        this.userData = JSON.parse(localStorage.getItem('userData'));
        if (this.userData) {
            this.getBags();
        }
        this.createOnline$().subscribe((isOnline) => {
            this.systemStatus = isOnline;
            if (!isOnline) {
                this.openSnack('Network Error, Check Internet');
            }
            else {
                this.openSnack('System Connected');
            }
        });
    }

    createOnline$() {
        return merge<boolean>(
            fromEvent(window, 'offline').pipe(map(() => false)),
            fromEvent(window, 'online').pipe(map(() => true)),
            new Observable((sub: Observer<boolean>) => {
                sub.next(navigator.onLine);
                sub.complete();
            }));
    }


    openSnack(message) {
        this._snackBar.open(message, '', {
            duration: 2000,
            horizontalPosition: "center",
            verticalPosition: 'top'
        });
    }

    getStatesMaster() {
        return this.http.get(this.baseUrlv1 + 'merchandiseMaster/allStates');
    }

    getBrandDataByCode(brandCode) {
        return this.http.get(this.baseUrl + 'brandProfile/brandDetailsByBrandCode/' + brandCode).pipe(share());
    }

    getConnectedDistributorsByBrandId(brandId): Observable<any> {
        return this.http.get(this.baseUrl + 'connection/distributorsOfBrandById/' + brandId);
    }

    getConnectedDistributors(body): Observable<any> {
        return this.http.post(this.baseUrl + 'connection/getConnectedDistributors/0/1000',body);
    }

    getConnectedRetailersByBrandId(brandId): Observable<any> {
        return this.http.get(this.baseUrl + 'connection/retailersOfBrandById/' + brandId);
    }

    getBrandsByUserId(): Observable<any> {
        const body = new FormData();
        body.append('companyId', this.companyId);
        body.append('userId', this.userData._id);
        return this.http.post(this.baseUrl + 'brandProfile/brandsByUser', body);
    }

    getBrandsWareHousesByBrandId(brandId): Observable<any> {

        return this.http.get(this.baseUrl + 'brandWarehouse/warehousesByBrand/' + brandId);

    }

    createBag(body): Observable<any> {
        return this.http.post(this.baseUrl + 'brandBag/createBag', body);
    }

    editBag(body): Observable<any> {
        return this.http.patch(this.baseUrl + 'brandBag/updateBag', body);
    }

    getBags() {
        const body = new FormData();
        body.append('company_id', this.companyId);
        body.append('user_id', this.userData._id);
        this.http.post(this.baseUrl + 'brandBag/getBagByUser/', body).subscribe(response => {
            this.handlebags(response);
        })
    }
    handlebags(response) {
        this.bags.next(response.data);
        this.bags.subscribe((data) => {
            this.bagNames = data;
            var index = data.length - 1;
            this.selectedBag = data[index];
        })
    }

    confirmDialog(message) {
        return new Promise((resolve, reject) => {
            var dialogref = this.dialog.open(ConfirmComponent, {
                data: {
                    message: message
                },
                width: '300px'
            });
            var result = dialogref.afterClosed().subscribe((data) => {
                resolve(data);
            });
        })
    }

    openSuccess(message) {
        this.dialog.open(SuccessComponent, {
            data: {
                message: message
            },
            width: '300px'
        });
    }

    errorDialog(message) {
        this.dialog.open(ErrorComponent, {
            data: {
                message: message
            },
            width: '300px'
        });
    }

    deleteBag(id) {
        const body = new FormData();
        body.append('bag_id', id);
        this.http.patch(this.baseUrl + 'brandBag/deleteBag/', body).subscribe(response => {
            this.getBags();
        })
    }

    logout() {
        this.Token = '';
        this.userData = '';
        localStorage.clear();
        this.router.navigate(['/login']);
    }

    notifications = [];
    notificationsCount = 0;
    getNotification() {
        this.notifications = [];
        this.getBrandsByUserId().subscribe((brands: any) => {
            for (let br of brands.data) {
                const body = new FormData;
                body.append('brandId', br._id);
                this.http.post(this.baseUrlv1 + 'notification/listForBrand', body).subscribe((response: any) => {
                    this.notifications = this.notifications.concat(response.data);
                    var unreadNotification = [];
                    var array = from(this.notifications);
                    var source = array.pipe(
                        filter((val: any) => val.is_read_by_brand == false)
                    )
                    const subscribe = source.subscribe((val: any) => {
                        unreadNotification.push(val);
                    })
                    this.notificationsCount = unreadNotification.length;
                    this.notifications = this.notifications.sort((a, b) => {
                        var c: any = new Date(a.createdOn);
                        var d: any = new Date(b.createdOn);
                        return d - c;
                    })
                })
            }
        })

    }

    getUniqueListBy(arr, key) {
        return [...new Map(arr.map(item => [item[key], item])).values()]
    }

    readNotification() {
        this.getBrandsByUserId().subscribe((response: any) => {
            for (let br of response.data) {
                const body = new FormData;
                body.append('brandId', br._id);
                this.http.patch(this.baseUrlv1 + 'notification/readAllBrandNotifications', body).subscribe((response: any) => {
                })
            }
        })
    }

    beep() {
        const audio = new Audio();
        audio.src = './assets/beep.wav';
        audio.load();
        audio.play();
    }
}
