import { FuseNavigation } from '@fuse/types';

export const navigation: FuseNavigation[] = [
    {
        id: 'overview',
        title: 'Overview',
        type: 'group',
        icon: 'apps',
        children: [
            {
                id: 'dashboards',
                title: 'Dashboards',
                translate: 'NAV.DASHBOARDS',
                type: 'item',
                icon: 'dashboard',
                url: '/dashboard'
            },
            // {
            //     id: 'reports',
            //     title: 'Reports',
            //     type: 'item',
            //     icon: 'pie_chart',
            //     url: '/reports'
            // }
            // {
            //     id: 'cart',
            //     title: 'Cart',
            //     type: 'item',
            //     icon: 'shopping_cart',
            //     url: '/cart'
            // },
            // {
            //     id: 'mail',
            //     title: 'Mail',
            //     translate: 'NAV.MAIL.TITLE',
            //     type: 'item',
            //     icon: 'email',
            //     url: '/apps/mail',
            //     badge: {
            //         title: '25',
            //         translate: 'NAV.MAIL.BADGE',
            //         bg: '#F44336',
            //         fg: '#FFFFFF'
            //     }
            // },
            // {
            //     id: 'calendar',
            //     title: 'Calendar',
            //     translate: 'NAV.CALENDAR',
            //     type: 'item',
            //     icon: 'today',
            //     url: '/apps/calendar'
            // },
            // {
            //     id: 'chat',
            //     title: 'Chat',
            //     translate: 'NAV.CHAT',
            //     type: 'item',
            //     icon: 'chat',
            //     url: '/apps/chat',
            //     badge: {
            //         title: '13',
            //         bg: '#09d261',
            //         fg: '#FFFFFF'
            //     }
            // },
            // {
            //     id: 'to-do',
            //     title: 'To-Do',
            //     translate: 'NAV.TODO',
            //     type: 'item',
            //     icon: 'check_box',
            //     url: '/apps/todo',
            //     badge: {
            //         title: '3',
            //         bg: '#FF6F00',
            //         fg: '#FFFFFF'
            //     }
            // },
        ]
    },
    {
        id: 'partners',
        title: 'Partners',
        type: 'group',
        icon: 'apps',
        children: [
            // {
            //     id: 'brands',
            //     title: 'Brands',
            //     type: 'item',
            //     icon: 'loyalty',
            //     url: '/brands'
            // },
            // {
            //     id: 'stores',
            //     title: 'Retailers',
            //     type: 'item',
            //     icon: 'store',
            //     url: '/stores'
            // },
            // {
            //     id: 'agent',
            //     title: 'Agents',
            //     type: 'item',
            //     icon: 'real_estate_agent',
            //     url: '/agents'
            // },
            {
                id: 'venues',
                title: 'Venues',
                type: 'item',
                icon: 'real_estate_agent',
                url: '/venues'
            },
            // {
            //     id: 'contacts',
            //     title: 'Contact Book',
            //     type: 'item',
            //     icon: 'account_box',
            //     url: '/apps/contacts'
            // },
        ]
    },
    // {
    //     id: 'transactions',
    //     title: 'Transactions',
    //     type: 'group',
    //     icon: 'apps',
    //     children: [
    //         {
    //             id: 'orders',
    //             title: 'Orders',
    //             type: 'item',
    //             icon: 'receipt',
    //             url: '/orders/1'
    //         },
    //         {
    //             id: 'shipments',
    //             title: 'Shipments',
    //             type: 'item',
    //             icon: 'local_shipping',
    //             url: '/shipments'
    //         },
    //         // {
    //         //     id: 'linesheets',
    //         //     title: 'Line Sheets',
    //         //     // translate: 'NAV.BRANDS',
    //         //     type: 'item',
    //         //     icon: 'developer_board',
    //         //     url: '/linesheet'
    //         // },
    //         {
    //             id: 'bags',
    //             title: 'Bags',
    //             type: 'item',
    //             icon: 'shopping_cart',
    //             url: '/cart/bags'
    //         }
    //
    //         // {
    //         //     id: 'links',
    //         //     title: 'ik-Flybook',
    //         //     type: 'item',
    //         //     icon: 'insert_link',
    //         //     url: '/links'
    //         // }
    //     ]
    // },
    // {
    //     id: 'config',
    //     title: 'Configurations',
    //     type: 'group',
    //     icon: 'apps',
    //     children: [
    //         // {
    //         //     id: 'grades',
    //         //     title: 'Grades',
    //         //     type: 'item',
    //         //     icon: 'account_tree',
    //         //     url: '/grades'
    //         // },
    //         // {
    //         //     id: 'groups',
    //         //     title: 'Groups',
    //         //     type: 'item',
    //         //     icon: 'hub',
    //         //     url: '/groups'
    //         // },
    //         {
    //             id: 'upload',
    //             title: 'File Uploads',
    //             type: 'item',
    //             icon: 'cloud_upload',
    //             url: '/upload/file/catalogue'
    //         },
    //
    //         {
    //             id: 'settings',
    //             title: 'Settings',
    //             type: 'item',
    //             icon: 'settings',
    //             url: '/settings'
    //         }
    //     ]
    // },
    // {
    //     id: 'b2c',
    //     title: 'E-Commerce',
    //     type: 'group',
    //     icon: 'pages',
    //     children: [
    //         {
    //             id: 'config',
    //             title: 'Homepage',
    //             type: 'collapsable',
    //             icon: 'local_mall',
    //             children: [
    //                 {
    //                     id: 'banner',
    //                     title: 'Banners',
    //                     type: 'item',
    //                     url: '/b2c/Configuration/banners'
    //                 },
    //                 {
    //                     id: 'collections',
    //                     title: 'Collections',
    //                     type: 'item',
    //                     url: '/b2c/Configuration/collections'
    //                 },
    //                 {
    //                     id: 'products',
    //                     title: 'Products',
    //                     type: 'item',
    //                     url: '/b2c/Configuration/products'
    //                 }
    //             ]
    //         }
    //     ]
    // },


];
