import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { RatiosService } from 'app/models/ratios.service';

@Component({
    selector: 'app-input-ratio',
    templateUrl: './input-ratio.component.html',
    styleUrls: ['./input-ratio.component.scss']
})
export class InputRatioComponent implements OnInit {

    Ratio: any = [
        {
            Brick: "Shirts",
            attribute_data: [{
                key: "Sleeve",
                value: 'Full Sleeve'
            }],
            title: 'Full Sleeve Shirts',
            size_run: [
                {
                    grade: 'A',
                    size: [
                        { size: '38', value: 0 },
                        { size: '39', value: 0 },
                        { size: '40', value: 0 },
                        { size: '42', value: 0 },
                        { size: '44', value: 0 },
                        { size: '46', value: 0 }
                    ]
                },
                {
                    grade: 'B',
                    size: [
                        { size: '38', value: 0 },
                        { size: '39', value: 0 },
                        { size: '40', value: 0 },
                        { size: '42', value: 0 },
                        { size: '44', value: 0 },
                        { size: '46', value: 0 }]
                },
                {
                    grade: 'C',
                    size: [
                        { size: '38', value: 0 },
                        { size: '39', value: 0 },
                        { size: '40', value: 0 },
                        { size: '42', value: 0 },
                        { size: '44', value: 0 },
                        { size: '46', value: 0 }]
                },
                {
                    grade: 'D',
                    size: [
                        { size: '38', value: 0 },
                        { size: '39', value: 0 },
                        { size: '40', value: 0 },
                        { size: '42', value: 0 },
                        { size: '44', value: 0 },
                        { size: '46', value: 0 }]
                }
            ]
        },
        {
            Brick: "Shirts",
            attribute_data: [{
                key: "Sleeve",
                value: 'Half Sleeve'
            }],
            title: 'Half Sleeve Shirts',
            size_run: [
                {
                    grade: 'A',
                    size: [
                        { size: '38', value: 0 },
                        { size: '39', value: 0 },
                        { size: '40', value: 0 },
                        { size: '42', value: 0 },
                        { size: '44', value: 0 },
                        { size: '46', value: 0 }
                    ]
                },
                {
                    grade: 'B',
                    size: [
                        { size: '38', value: 0 },
                        { size: '39', value: 0 },
                        { size: '40', value: 0 },
                        { size: '42', value: 0 },
                        { size: '44', value: 0 },
                        { size: '46', value: 0 }]
                },
                {
                    grade: 'C',
                    size: [
                        { size: '38', value: 0 },
                        { size: '39', value: 0 },
                        { size: '40', value: 0 },
                        { size: '42', value: 0 },
                        { size: '44', value: 0 },
                        { size: '46', value: 0 }]
                },
                {
                    grade: 'D',
                    size: [
                        { size: '38', value: 0 },
                        { size: '39', value: 0 },
                        { size: '40', value: 0 },
                        { size: '42', value: 0 },
                        { size: '44', value: 0 },
                        { size: '46', value: 0 }]
                }
            ]
        },
        {
            Brick: "Jeans",
            attribute_data: [{}],
            title: 'Jeans',
            size_run: [
                {
                    grade: 'A',
                    size: [
                        { size: '28', value: 0 },
                        { size: '30', value: 0 },
                        { size: '32', value: 0 },
                        { size: '34', value: 0 },
                        { size: '36', value: 0 },
                        { size: '38', value: 0 },
                        { size: '40', value: 0 }]
                },
                {
                    grade: 'B',
                    size: [
                        { size: '28', value: 0 },
                        { size: '30', value: 0 },
                        { size: '32', value: 0 },
                        { size: '34', value: 0 },
                        { size: '36', value: 0 },
                        { size: '38', value: 0 },
                        { size: '40', value: 0 }]
                },
                {
                    grade: 'C',
                    size: [
                        { size: '28', value: 0 },
                        { size: '30', value: 0 },
                        { size: '32', value: 0 },
                        { size: '34', value: 0 },
                        { size: '36', value: 0 },
                        { size: '38', value: 0 },
                        { size: '40', value: 0 }]
                },
                {
                    grade: 'D',
                    size: [
                        { size: '28', value: 0 },
                        { size: '30', value: 0 },
                        { size: '32', value: 0 },
                        { size: '34', value: 0 },
                        { size: '36', value: 0 },
                        { size: '38', value: 0 },
                        { size: '40', value: 0 }]
                }
            ]
        },
        {
            Brick: "Trousers",
            attribute_data: [{}],
            title: 'Trousers',
            size_run: [
                {
                    grade: 'A',
                    size: [
                        { size: '28', value: 0 },
                        { size: '30', value: 0 },
                        { size: '32', value: 0 },
                        { size: '34', value: 0 },
                        { size: '36', value: 0 },
                        { size: '38', value: 0 },
                        { size: '40', value: 0 },
                        { size: '42', value: 0 }]
                },
                {
                    grade: 'B',
                    size: [
                        { size: '28', value: 0 },
                        { size: '30', value: 0 },
                        { size: '32', value: 0 },
                        { size: '34', value: 0 },
                        { size: '36', value: 0 },
                        { size: '38', value: 0 },
                        { size: '40', value: 0 },
                        { size: '42', value: 0 }]
                },
                {
                    grade: 'C',
                    size: [
                        { size: '28', value: 0 },
                        { size: '30', value: 0 },
                        { size: '32', value: 0 },
                        { size: '34', value: 0 },
                        { size: '36', value: 0 },
                        { size: '38', value: 0 },
                        { size: '40', value: 0 },
                        { size: '42', value: 0 }]
                },
                {
                    grade: 'D',
                    size: [
                        { size: '28', value: 0 },
                        { size: '30', value: 0 },
                        { size: '32', value: 0 },
                        { size: '34', value: 0 },
                        { size: '36', value: 0 },
                        { size: '38', value: 0 },
                        { size: '40', value: 0 },
                        { size: '42', value: 0 }]
                }
            ]
        }
    ];
    Grades: any = ["A", "B", "C", "D"];

    constructor(public dialogRef: MatDialogRef<InputRatioComponent>, @Inject(MAT_DIALOG_DATA) public data, public api: RatiosService) {
        this.Ratio = this.api.OriginalRatio;
    }

    ngOnInit(): void {
        this.setFlags();
    }
    setFlags() {
        // var ratio = JSON.parse(localStorage.getItem('LocalRatio'));
        const body = new FormData();
        body.append('bag_id', this.data.bag_id);
        this.api.getBagRatio(body).subscribe((response: any) => {
            if (response.data.length > 0) {
                if (response.data[0].ratios) {
                    this.Ratio = JSON.parse(response.data[0].ratios);
                }
                else {
                    this.resetRatio();
                }
            }
            else {
                this.resetRatio();
            }
        })


    }


    dismiss() {
        this.dialogRef.close({
            data: this.Ratio
        });
    }


    setRatioLocal() {
        const body = new FormData();
        body.append('brand_id', this.data.brand_id);
        body.append('bag_id', this.data.bag_id);
        body.append('ratios', JSON.stringify(this.Ratio));

        this.api.setBagRatio(body).subscribe((response: any) => {
            if (response.status == 200) {
                this.dismiss();
            }
        })

    }

    resetRatio1() {
        this.Ratio = this.api.OriginalRatio;
        const body = new FormData();
        body.append('brand_id', this.data.brand_id);
        body.append('bag_id', this.data.bag_id);
        body.append('ratios', JSON.stringify(this.Ratio));

        this.api.setBagRatio(body).subscribe((response: any) => {
            if (response.status == 200) {

            }
        })
    }

    resetRatio() {
        this.Ratio = this.api.OriginalRatio;
        // localStorage.setItem('LocalRatio',JSON.stringify(this.Ratio));
    }


}
