<div fxLayout="row wrap" fxLayoutAlign="space-between center" *ngIf="data">
    <div fxLayout="column" class="font-weight-700 font-size-18">{{data.mode}} Bag</div>
    <div fxLayout="column">
        <button mat-icon-button (click)="dismiss()">
            <mat-icon>close</mat-icon>
        </button>
    </div>
</div>

<div fxLayout="row wrap" fxLayoutAlign="start center" class="mt-16" *ngIf="data">
    <div fxLayout="column" fxFlex="100">
            <mat-form-field class="w-100-p" appearance="fill">
                <input matInput name="bagName" placeholder="Bag Name" [(ngModel)]="bagName">
            </mat-form-field>
            <mat-form-field class="w-100-p" appearance="fill">
                <textarea matInput name="Remarks" [(ngModel)]="remarks" placeholder="Add Remarks,Notes .."></textarea>
            </mat-form-field>
            <div fxLayout="row" fxLayoutAlign="end center" *ngIf="data.mode == 'Create'">
                <button type="submit" mat-raised-button color="primary" (click)="createBag()"> Create Bag </button>
            </div>
            <div fxLayout="row" fxLayoutAlign="end center" *ngIf="data.mode == 'Edit'">
                <button type="submit" mat-raised-button color="primary" (click)="EditBag()"> Edit Bag </button>
            </div>
    </div>
</div>
