<div fxLayout="row wrap">
    <div fxLayout="column" fxFlex="50">
        <mat-form-field class="w-100-p px-4">
            <mat-label>Select Column for EAN Code</mat-label>
            <mat-select [(ngModel)]="keyForEAN">
                <mat-option *ngFor="let key of keys" [value]="key">{{key}}</mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <div fxLayout="column" fxFlex="50">
        <mat-form-field class="w-100-p">
            <mat-label>Select Column for Quantity</mat-label>
            <mat-select [(ngModel)]="keyForQty">
                <mat-option *ngFor="let key of keys" [value]="key">{{key}}</mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <div fxLayout="column" fxFlex="50">
        <mat-form-field class="w-100-p px-4">
            <mat-label>Select Brand</mat-label>
            <mat-select [(ngModel)]="selectedBrand">
                <mat-option *ngFor="let brand of brands" [value]="brand._id">{{brand.brand_name}}</mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <div fxLayout="column" fxFlex="50">
        <mat-form-field class="w-50-p px-4">
            <mat-label>Bucket Type</mat-label>
            <mat-select [(ngModel)]="SelectedBuckets">
                <mat-option value="ON-HAND">ON HAND</mat-option>
                <mat-option value="BOX-NOT-OPEN">BOX NOT OPEN</mat-option>
                <mat-option value="WIP-30">WIP-30</mat-option>
                <mat-option value="WIP-60">WIP-60</mat-option>
                <mat-option value="WIP-90">WIP-90</mat-option>
                <mat-option value="FOR-SALE">FOR-SALE</mat-option>
                <mat-option value="CUTSIZE">CUTSIZES</mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <div fxLayout="column" fxFlex="100">
        <mat-form-field class="w-50-p px-4">
            <mat-label>Warehouse</mat-label>
            <mat-select [(ngModel)]="selectedBrandWarehouse">
              <mat-option *ngFor="let warehouse of warehouses" [value]="warehouse._id">
                {{warehouse.name}}
              </mat-option>
            </mat-select>
        </mat-form-field>
    </div>

</div>
<div fxLayout="row wrap" style="max-height:65vh;overflow-y:auto" *ngIf="errorContainer">
    <table class="w-100-p" style="color:red">
        <tr style="background:#f5f5f5">
            <th class="text-left p-4">EAN Code</th>
            <th class="text-left p-4">Quantity</th>
        </tr>
        <tr *ngFor="let rows of error1">
            <td class="text-left">{{rows.EAN_code}}</td>
            <td class="text-left">{{rows.quantity}}</td>
        </tr>
    </table>
</div>

<div fxLayout="row wrap" style="max-height:65vh;overflow-y:auto" *ngIf="!errorContainer">
    <table class="w-100-p">
        <tr style="background:#f5f5f5">
            <th class="text-left p-4">EAN Code</th>
            <th class="text-left p-4">Quantity</th>
        </tr>
        <tr *ngFor="let rows of data.array">
            <td class="text-left">{{rows[keyForEAN]}}</td>
            <td class="text-left">{{rows[keyForQty]}}</td>
        </tr>
    </table>
</div>

<div fxLayout="row wrap" fxLayoutAlign="end end">
    <button mat-raised-button (click)="importBag()" color="primary" *ngIf="!errorContainer && !DoneBtn" [disabled]="importing">Import File</button>
    <button mat-raised-button (click)="onNoClick()" color="accent" *ngIf="DoneBtn">Done</button>
</div>
