import { Component, OnInit, Inject } from '@angular/core';
import { UserService } from 'app/user.service';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { from } from 'rxjs';
import { reduce, filter } from 'rxjs/operators';
import { UploadService } from 'app/uploads/upload.service';
import { FuseProgressBarService } from '@fuse/components/progress-bar/progress-bar.service';

@Component({
    selector: 'app-bulk-upload-images',
    templateUrl: './bulk-upload-images.component.html',
    styleUrls: ['./bulk-upload-images.component.scss']
})
export class BulkUploadImagesComponent implements OnInit {

    files:any = [];
    selectedBrand:any;
    constructor(public user: UserService, public dialogRef: MatDialogRef<BulkUploadImagesComponent>, public dialog: MatDialog,public api: UploadService, @Inject(MAT_DIALOG_DATA) public data,public progress:FuseProgressBarService) {
      this.selectedBrand = this.api.brands[0];
    }

    handleFileInput(files: FileList) {
        this.files = files;
        var array = from(files);
        var source = array.pipe(
            reduce((acc, val) => acc + val.size, 0)
        )
        const subscribe = source.subscribe((val: any) => {
            if (val > 104857600) {
                this.user.errorDialog('Bulk upload should be below 100Mb');
            }
            else {
              for (let f of this.files) {
                  var name = f.name.split('-');
                  if (f.size > 300000) {
                    f['error'] = true;
                    f['errormsg'] = 'Size above 300kb';
                  }
                  else if (JSON.stringify(f.type) === "image/jpeg") {
                    f['error'] = true;
                    f['errormsg'] = 'Invalid File type';
                  }
                  else if (name.length !== 3) {
                      f['error'] = true;
                      f['errormsg'] = 'Invalid File Name';
                  }
                  else
                  {
                    f['error'] = false;
                  }
                  console.log(f);
              }
            }
        });
    }


    ngOnInit(): void {

    }

    errors = [];
    invalidFiles = [];

    UploadFiles() {
      var files = this.files;
      var array = from(this.files);
      var source = array.pipe(
          filter((val:any) => val.error == true)
      )
      const subscribe = source.subscribe((val: any) => {
        this.errors.push(val);
      })
        if(this.errors.length == 0){
          this.progress.show();
          var body = new FormData;
          body.append('brandCode',this.selectedBrand.brand_code);
          for(let x of files){
            body.append('images',x);
          }

          this.api.uploadS3Images(body).subscribe((response:any)=>{
              if(response.errorFiles.length == 0){
                this.user.openSuccess('Images Uploaded Successfully');
                this.progress.hide();
                this.dialogRef.close();
              }
              else{
                this.invalidFiles = response.errorFiles;
                  this.progress.hide();

              }

          })
        }
        else{
          this.progress.hide();
          this.user.errorDialog('Please correct the errors and try again');
        }


    }



}
