import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { CartService } from 'app/cart/cart.service';
import { UserService } from 'app/user.service';
import { from } from 'rxjs';
import { map, findIndex } from 'rxjs/operators';
import { FuseProgressBarService } from '@fuse/components/progress-bar/progress-bar.service';

@Component({
    selector: 'app-generate-link',
    templateUrl: './generate-link.component.html',
    styleUrls: ['./generate-link.component.scss']
})
export class GenerateLinkComponent implements OnInit {

    mobvalue:any;
    linkName = "";
    isPrivate: any = false;
    mobileNumbers: any = [];
    today: any = new Date();
    published_date: any = new Date(new Date().setDate(this.today.getDate()));
    eDate: any = new Date(new Date().setDate(this.today.getDate() + 30));
    is_published: any = true;
    mobileErrors = [];
    constructor(public _fuseProgressBarService: FuseProgressBarService, public dialogRef: MatDialogRef<GenerateLinkComponent>, public router: Router,
        @Inject(MAT_DIALOG_DATA) public data, public dialog: MatDialog, public cart: CartService, public user: UserService) {

    }

    ngOnInit(): void {
    }

    dismiss(): void {
        this.dialog.closeAll();
    }

    error: any;
    generateLink() {
        this.error = null

        if (this.linkName && this.published_date && this.eDate) {
            // var EAN_Codes = [];
            // const out = from(this.data.cartArray)
            // const eans = out.pipe(map(({ EAN_code }) => EAN_code));
            // const subscribe = eans.subscribe(val => EAN_Codes.push(val));
            var user = { "user_id": this.user.userData._id, "user_first_name": this.user.userData.userFirstName, "user_last_name": this.user.userData.userLastName }
            const body = new FormData();
            body.append('company_id', this.user.companyId);
            body.append('brand_id', this.data.brand_id);
            body.append('bag_id',this.data.bag_id);
            body.append('user_id',this.user.userData._id);
            body.append('link_name', this.linkName);
            // body.append('ean_codes', JSON.stringify(EAN_Codes));
            body.append('is_private', this.isPrivate);
            body.append('mobile_numbers', JSON.stringify(this.mobileNumbers));
            body.append('created_user', JSON.stringify(user));
            body.append('expire_date', this.eDate);
            body.append('published_date', this.published_date);
            body.append('is_published', this.is_published);
            this._fuseProgressBarService.show();
            this.cart.GenerateLink(body).subscribe((response: any) => {
                this._fuseProgressBarService.hide();
                this.user.openSuccess('Link Successfully Generated');
                this.dismiss();
            }, error => {
                this.user.errorDialog('Link Not Created, Check all Fields')
            })
        }
        else {
            this.error = "ALL FIELDS ARE MANDATORY"
        }
    }

    addMobile(data){
      if(data.length == 12 || data.length == 14){
        var array = from(this.mobileNumbers);
        var result = array.pipe(findIndex((val: any) =>
            data === val
        ));
        result.subscribe(index => {
            if (index == -1) {
                this.mobileNumbers.push(data);
                this.mobvalue = null;
            }
            else{
                this.error = "Mobile Number Already Added"
            }
        });
      }
      else{
        this.error = "Invalid Number, Add Country Code without + "
      }
    }


}
